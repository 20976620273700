import React from "react";
import "./ActionBar.scss";
import { ReactComponent as ArrowSVG } from "./assets/arrow.svg";

export default function ActionBar({ steps = [], container }) {
  const { action, currentStep, buttonText, isDisabled } = container;

  return (
    <footer className="action-bar audit">
      {steps.map((label, i) => (
        <div key={i} className={currentStep === i ? "active" : ""}>
          <div>
            <span className="step-label">Step {i + 1}</span>
            <br />
            <span>{label}</span>
          </div>
        </div>
      ))}
      <button
        disabled={!action}
        className={isDisabled ? "disabled" : ""}
        onClick={action}
      >
        {buttonText}
        <ArrowSVG />
      </button>
    </footer>
  );
}
