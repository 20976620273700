import { useEffect, useRef } from "react";

/**
 * Hook that alerts clicks outside of the passed ref
 */
function useOutsideAlerter(ref, setVisible) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setVisible(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, setVisible]);
}

export default function Tooltip({ children, setVisible }) {
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, setVisible);

  return <div ref={wrapperRef}>{children}</div>;
}

export { Tooltip, useOutsideAlerter };
