import React from "react";
import "./PageTitle.scss";

export default function PageTitle({
  children,
  rightTitle,
  hideSearch = false,
  maxWidth
}) {
  return (
    <div className={ maxWidth ? "page-title max-width": "page-title" }>
      <span>{children}</span>
      {rightTitle && <span>{rightTitle}</span>}
      {!hideSearch && <input type="text" placeholder="Search"></input>}
    </div>
  );
}
