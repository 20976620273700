import "./TileWidget.scss";

export const TileWidget = ({ data = [] }) => {
  return (
    <div className="widget-wrapper">
      {data.map(({ label, value }, index) => (
        <div key={index} className={`widget ${!value ? "greyed" : ""}`}>
          <h4>{value || "-"}</h4>
          <p>{label}</p>
        </div>
      ))}
    </div>
  );
};
