import React, { useState } from "react";
import { Link, useResolvedPath, useMatch, useLocation } from "react-router-dom";
import ActionBar from "./ActionBar";
import "./LeftNavV1.scss";
import PageTitle from "./PageTitle";

function CustomLink({ base, label, path, disabled, children, disableAll, f }) {
  const to = base + path;
  let resolved = useResolvedPath(to);
  let match = useMatch({ path: resolved.pathname, end: true });
  return (
    <li key={path} className={match ? "active" : ""}>
      {(disabled || disableAll) ? (
        <span className="menuItemSide disabled">
          {label}
          {children}
        </span>
      ) : (
        <Link onClick={f && f()} to={to} className="menuItemSide">
          {label}
          {children}
        </Link>
      )}
    </li>
  );
}

export default function LeftNavV1({
  base,
  children,
  pageTitle,
  rightTitle,
  display,
  text,
  items = [],
  steps,
  disabled,
  container,
  maxWidth
}) {
  const [collapsed, setCollapsed] = useState(true);
  const location = useLocation();
  function getPageTitle() {
    if (pageTitle) return pageTitle;
    const menuItem =
      items.find(
        ({ path }) => location.pathname === base + path.replace(/\?.*/, "")
      ) || {};
    return menuItem.pageTitle || menuItem.label || "";
  }
  
  return (
      <div className="side-wrapper">
        {display &&
        <div className={collapsed ? "jw-drawer collapsed-nav" : "jw-drawer"}>
          <nav>
            <ul>
              <li>
                <button type="button" onClick={()=>setCollapsed((state)=>!state)} className="side-nav">
                  <div>
                    <p>{text}</p>
                  </div>
                  <div>
                    <span></span>
                    <span></span>
                  </div>
                </button>
              </li>
              {items.map(({ label, path, Svg, ...rest }) => (
                <CustomLink
                  label={label}
                  path={path}
                  base={base}
                  key={path}
                  {...rest}
                  disableAll={disabled}
                >
                  {Svg}
                </CustomLink>
              ))}
            </ul>
          </nav>
        </div>}
        <div className={display ? "content" : "content no-nav"}>
          <div className="container__main">
            {getPageTitle() !== "" && 
            <PageTitle hideSearch rightTitle={rightTitle} maxWidth={maxWidth}>
              {getPageTitle()}
            </PageTitle>}
            {children}
          </div>
        </div>
        {steps && container && steps.length > 0 &&
            <ActionBar steps={steps} container={container} />}
      </div>
  );
}
