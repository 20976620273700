import { useEffect, useMemo, useState } from "react";
import Rating from "./Rating";
import "./AuditComponent.scss";
import MultipleChoiceQuestion from "./MultipleChoiceQuestion";
import TextQuestion from "./TextQuestion";
import CheckboxQuestion from "./CheckboxQuestion";
import Accordion from "./Accordion.jsx";
import ViewGradingFlow from "./ViewGradingFlow";
import { useDebouncedCallback } from "use-debounce";
import CommentBox from "./CommentBox";
import ToolTip from "./Tooltip";

const gradingFlow = (module) => {
  if (module === "QEPA") return <ViewGradingFlow />;

  return "";
};

const QuestionType = ({
  question: {
    type,
    id,
    questionAnswers,
    stringId,
    index,
    parent,
    children,
    ...rest
  },
  isValidating,
  answers,
  setAnswers,
  questions,
  multipleIndex,
  validate,
}) => {
  const map = {
    "multiple-choice": MultipleChoiceQuestion,
    "text-input": TextQuestion,
    "yes/no": CheckboxQuestion,
  };

  const Component = map[type];

  const multipleChildren = useMemo(
    () => children?.filter((c) => !c.answerId),
    [children]
  );
  const showChildren = useMemo(
    () => children?.filter((c) => c.answerId),
    [children]
  );

  const value = multipleIndex
    ? answers.answers?.find(
        (a) => a.questionId === id && multipleIndex === a.index
      )
    : answers.answers?.find((a) => a.questionId === id);

  const answered = useMemo(() => {
    const passed = validate(
      [{ ...value, type }],
      [{ id, questionAnswers, type, childIndex: multipleIndex }]
    );
    const isNotApplicable = value?.inputLabel === 'Not applicable'
    return passed || isNotApplicable;
  }, [validate, value, type, id, questionAnswers, multipleIndex]);

  /**
   * Returns a child question count, handling -1 for not applicables.
   * @param {undefined|null|Number} num - child count.
   * @returns {Number}
   */
  const getChildCount = (num) => {
    if (isNaN(parseInt(num)) || parseInt(num) < 0) return 0;
    return parseInt(num);
  };

  return (
    <>
      <Component
        key={id}
        options={questionAnswers}
        {...rest}
        {...(isValidating && !answered ? { className: "invalid" } : {})}
        value={value}
        onChange={(a) => {
          const answerId =
            questionAnswers.find((qa) => qa?.options?.value === a?.value)?.id ||
            a?.answerId ||
            null;
          setAnswers({
            comment: answers.comment,
            answer: {
              answerId,
              questionResponseId: answers?.questionResponseId,
              questionId: id,
              index: multipleIndex,
              comment: a?.comment,
              valueInput: a?.value,
            },
          });
        }}
      />
      {multipleChildren?.length > 0 &&
        [
          ...Array(
            getChildCount(
              questionAnswers?.find((a) => a.id === value?.answerId)?.options
                .value
            )
          ),
        ].map((v, n) => (
          <div className="multiple" key={n}>
            <div className="number-ball">
              <p>{n + 1}</p>
            </div>
            {multipleChildren.map(({ childId }) => {
              const question = questions.find(({ id }) => id === childId);
              return (
                <QuestionType
                  key={`${question.id}-${multipleIndex}-${n + 1}`}
                  question={question}
                  isValidating={isValidating}
                  answers={answers}
                  setAnswers={setAnswers}
                  questions={questions}
                  validate={validate}
                  multipleIndex={parseInt(`${n + 1}${multipleIndex}`) || n + 1}
                />
              );
            })}
          </div>
        ))}
      {showChildren
        ?.filter((c) => c.answerId === value?.answerId)
        .map(({ childId }) => {
          const question = questions.find(({ id }) => id === childId);
          return (
            <QuestionType
              key={`${question.id}-${multipleIndex}`}
              question={question}
              isValidating={isValidating}
              answers={answers}
              setAnswers={setAnswers}
              questions={questions}
              multipleIndex={multipleIndex}
              validate={validate}
            />
          );
        })}
    </>
  );
};

export default function AuditComponent({
  index,
  questions = [],
  grading = [],
  isValidating = false,
  answers,
  setAnswers,
  hideSilver = false,
  perComplete,
  validate,
  optional,
  module,
  tooltip,
  showProgressBar = false,
}) {
  const [addComment, setAddComment] = useState(answers?.comment && true);

  // setDefaultOpenGroup is not used so disabling linting for this line
  // eslint-disable-next-line no-unused-vars
  const [defaultOpenGroup, setDefaultOpenGroup] = useState(
    answers.gradeId
      ? grading.find((g) => g.id === answers.gradeId)?.name.toLowerCase()
      : grading[1].name.toLowerCase()
  );
  const [themeComment, setThemeComment] = useState(answers.comment || "");

  const orderedQuestions = useMemo(
    () => questions.sort((a, b) => a.index - b.index),
    [questions]
  );
  const groupQuestions = useMemo(
    () =>
      orderedQuestions
        .filter(({ parent }) => !parent)
        .reduce((groups, item) => {
          const group = groups[item.group] || [];
          group.push(item);
          groups[item.group] = group;
          return groups;
        }, {}),
    [orderedQuestions]
  );

  const updateComment = useDebouncedCallback(
    (comment) => setAnswers({ answers, comment }),
    500
  );
  const handleUpdateComment = (value) => {
    setThemeComment(value);
    updateComment(value);
  };

  useEffect(() => {
    setAddComment(answers?.comment && true);
  }, [index, answers?.comment]);

  return (
    <>
      {!optional && !!perComplete && (
        <Rating
          active={answers.gradeId}
          grading={grading}
          hideSilver={hideSilver}
          perComplete={perComplete}
          module={module}
          showProgressBar={showProgressBar}
        />
      )}
      <div className="audit-wrapper">
        <div className="audit-title">
          {addComment && (
            <CommentBox
              comment={themeComment}
              placeholder="Please provide any other comments on the quality of this section"
              updateComment={handleUpdateComment}
            />
          )}
          {addComment ? (
            <button
              className="comment-button"
              onClick={() => {
                setAnswers({ answers, comment: null });
                setAddComment(false);
              }}
            >
              Remove this comment
            </button>
          ) : (
            <button
              className="comment-button"
              onClick={() => setAddComment(true)}
            >
              + Add additional comments on this theme
            </button>
          )}
        </div>
        <div className="audit-component">
          <div className="qa-title">
            <h2>QA Criteria</h2>
            {!!tooltip && (
              <ToolTip title="What does 'good' look like?" text={tooltip} />
            )}
            {gradingFlow(module)}
          </div>
          <div>
            {Object.keys(groupQuestions).map((group) => {
              const index = grading.findIndex(
                (g) => g.name.toLowerCase() === group.toLowerCase()
              );
              const colour = grading[index]?.colour;
              return (
                <div key={group}>
                  {group !== "null" ? (
                    <Accordion
                      key={group}
                      title={`${group[0].toUpperCase()}${group.slice(1)}`}
                      openByDefault={group === defaultOpenGroup}
                      colour={colour}
                    >
                      {groupQuestions[group].map((question) => (
                        <QuestionType
                          key={question.id}
                          question={question}
                          isValidating={isValidating}
                          answers={answers}
                          setAnswers={setAnswers}
                          questions={orderedQuestions}
                          validate={validate}
                        />
                      ))}
                    </Accordion>
                  ) : (
                    <>
                      {groupQuestions[group].map((question) => (
                        <QuestionType
                          key={question.id}
                          question={question}
                          isValidating={isValidating}
                          answers={answers}
                          setAnswers={setAnswers}
                          questions={orderedQuestions}
                          validate={validate}
                        />
                      ))}
                    </>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}
