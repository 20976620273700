import "./App.css";
import { BrowserRouter } from "react-router-dom";
import Router from "./Router";
import Helmet from "react-helmet";
import Suspense from "./Suspense";

/* Toast library. */
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import React from "react";
import ModuleContext from "./context/ModuleContext";

function App() {
  // if (process.env.NODE_ENV !== "production") {
  //   const { whyDidYouUpdate } = require("why-did-you-update");
  //   whyDidYouUpdate(React);
  // }

  if (!process.env.REACT_APP_MODULE_ID) {
    console.warn("NO MODULE ID IN .env!");
  }

  const title = () => {
    const moduleId = process.env.REACT_APP_MODULE_ID;

    switch (moduleId) {
      // QEPA
      case "e37d182b-a8e2-4342-8d93-3416b2ee6a1c":
        return "Invision360 Educational Psychology advice QA tool";
      // AR
      case "f17a1b26-61c5-11ed-9e9a-06fae64f1ba6":
        return "Invision360 Annual Review QA tool";
      default:
        return "Invision360";
    }
  }

  return (
    <>
        <Helmet
          defaultTitle={title()}
          titleTemplate={`%s | ${title()}`}
          defer={false}
        />
        <BrowserRouter>
          <Suspense>
            <ModuleContext.Provider>
              <Router />
            </ModuleContext.Provider>
          </Suspense>
          <ToastContainer autoClose={3000} />
        </BrowserRouter>
    </>
  );
}

export default App;
