import React from "react";

import { ReactComponent as Logo } from "./assets/logo.svg";

import "./feedbackheader.scss";

export function FeedbackHeader({ pretitle = "PEP Opinion - ", title }) {
  return (
    <header className="feedback-header">
      <span className="logo">
        <Logo />
      </span>
      <h1>
        {pretitle}
        {title}
      </h1>
    </header>
  );
}
