import { useEffect, useState, useMemo } from "react";
import { Modal } from "./Modal.jsx";
import ToolTip from "./Tooltip.jsx";

import "./AuditSummaryModalV2.scss";

const byIndex = ({ index: a }, { index: z }) => a - z;

function ThemeQuestions({ id: sectionId, responses, criteria, moderated, legacy }) {
  // this conditional has been added to deal with different alias caused by the query
  const alias = moderated ? "rq" : "responseQuestion";
  const questions = criteria.questions.sort(byIndex).map((q) => {
    const response = responses?.[alias]?.find(
      ({ questionId }) => questionId === q.id
    );
    return ({ ...q, response })
  })
  const answers = responses?.[alias]?.map((a) => {
    const question = criteria.questions.find(
      ({ id }) => a.questionId === id
    );
    const group = a.index ? "Outcome " + a.index : (question.group || null)
    return ({ ...question, response: a, group })
  }).sort(byIndex)
  const groupBy = (questions) => {
    const grouped = {}
    questions?.forEach(q => {
      if (!grouped[q.group]) grouped[q.group] = []
      grouped[q.group].push(q)

    })
    return grouped
  }
  const groupedAnswered = groupBy(answers?.filter(({ response }) => response))

  const grade = responses?.grade?.name.toLowerCase()
  const gradeMatchesCriteria = !!groupedAnswered[grade]

  const groupedUnanswered = groupBy(questions?.filter(({ response, type, group, parent }) => {
    let condition = !response && !parent
    if (gradeMatchesCriteria) {
      condition = condition && type !== 'yes/no'
    } else {
      const groupCondition = group === grade || !!groupedAnswered[group] || type !== 'yes/no' || criteria.optional
      condition = condition && groupCondition
    }
    return condition
  }))

  useEffect(() => {
    let element = document.getElementById('modal-section-title');
    element.scrollIntoView(true);
    return () => {
    }
  }, [sectionId])

  return (
    <>
      <h2 id="modal-section-title">{criteria.name + ": " + criteria.description}</h2>
      {responses?.comment && (
        <p>
          Comments on this theme: <i>{responses?.comment}</i>
        </p>
      )}
      {legacy && <p>This is a legacy audit and therefore will not show the underlying criteria.</p>}
      {!legacy && <>
        {!Object.entries(groupedAnswered).length && <p>No answers in this theme</p>}
        {Object.entries(groupedAnswered).map(([key, value]) => {
          return (<div key={key} className="criteria-group">
            <h4>{(key === "null" || value.length === 0) ? "" : (key[0].toUpperCase() + key.slice(1))}</h4>
            <ul>
              {value.map(({ id, name, questionAnswers: options, type, index, response }) => {
                const isCheckbox = type === "yes/no"
                const answer = response?.answerId || response?.value
                  ? options?.find(({ id }) => id === response.answerId)
                    ?.options?.label || response.value
                  : "(No response)"
                return (
                  <li key={id}>
                    <p>
                      {!isCheckbox && <b>{index}. </b>}
                      {name}
                    </p>
                    {(!isCheckbox || response?.comment) && <div>
                      {!isCheckbox && <p>{answer}</p>}
                      {response?.comment && (
                        <>
                          {" "}
                          with comment: <i>{response.comment}</i>
                        </>
                      )}
                    </div>}
                  </li>)
              })}
            </ul>
          </div>)
        })}
        {!!Object.entries(groupedUnanswered).length &&
          <>
            <hr style={{ borderColor: 'var(--dark-blue)', opacity: 0.2 }} />
            <h3>Unanswered / Not Selected</h3>
          </>}
        {Object.entries(groupedUnanswered).map(([key, value]) => {
          return (<div key={key} className="criteria-group">
            <h4>{(key === "null" || value.length === 0) ? "" : (key[0].toUpperCase() + key.slice(1))}</h4>
            <ul>
              {value.map(({ id, name, type, index }) => {
                return (<li key={id}>
                  <p>
                    {type !== "yes/no" && <b>{index}. </b>}
                    {name}
                  </p>
                </li>)
              })}

            </ul>
          </div>)
        })}
      </>}
    </>
  );
}

export const AuditSummaryModalV2 = ({
  title,
  data,
  controls,
  sectionId,
  criteria,
  moderated,
  closeText = "Close",
}) => {
  // Theme we're currently viewing.
  const [selectTheme, setSelectTheme] = useState({});
  // The audit is legacy if it does not have answers but is marked as completed
  const legacy = useMemo(() => {
    const alias = moderated ? "rq" : "responseQuestion";
    const allAnswers = data?.review?.responses?.reduce((prev, curr) => [
      ...prev,
      ...curr[alias]
    ], []);
    const completed = !!data?.completedAt || !!data.review?.outcomeId
    /* Filter out outcomes */
    const answers = allAnswers?.filter(({ index }) => !index)
    return completed && !answers.length;
  }, [data, moderated])
  useEffect(() => {
    setSelectTheme(
      sectionId
        ? criteria.criteria.find(({ id }) => id === sectionId)
        : criteria.criteria[0]
    );
  }, [sectionId, criteria?.criteria]);

  // Pane content memo.
  const renderSectionContent = useMemo(() => {
    switch (selectTheme) {
      case "actions":
        let strengths = data?.review?.appendices?.filter(
          ({ name }) => name === "strength"
        );
        const actions = data?.review?.appendices?.filter(
          ({ name }) => name === "action"
        );
        return (
          <>
            {!!strengths.length && (
              <>
                <h4>Strengths</h4>
                <ul>
                  {strengths?.map(({ value }, index) => (
                    <li key={index}>{value}</li>
                  ))}
                </ul>
                <br />
              </>
            )}
            {!!actions.length && (
              <>
                <h4>Actions</h4>
                <ul className="actions-list">
                  {actions
                    .map(({ value }, index) => (
                      <li key={index}>{value}</li>
                    ))}
                </ul>
              </>)}
          </>
        );
      default:
        return (
          <ThemeQuestions
            moderated={moderated}
            id={selectTheme?.id}
            legacy={legacy}
            responses={
              data?.review?.responses?.find(
                ({ criteriaId }) => criteriaId === selectTheme.id
              ) || {}
            }
            criteria={
              criteria.criteria.find(({ id }) => id === selectTheme?.id) || {}
            }
          />
        );
    }
  }, [data, selectTheme, criteria, moderated]);

  const overallGrade = useMemo(() => {
    return {
      tag: data?.review?.overallGrade?.name?.toLowerCase(),
      label: data?.review?.overallGrade?.name || "",
      backgroundColor: data?.review?.overallGrade?.colour,
    };
  }, [data?.review?.overallGrade?.colour, data?.review?.overallGrade?.name]);

  return (
    criteria?.criteria &&
    data && (
      <Modal
        title={<div style={{ display: "flex", alignItems: "center", position: "relative", gap: "1rem" }}>
          <h3>{title ? title + " - " : ""} {data.pepId}</h3>
          {legacy && <ToolTip title="Legacy" text="This is a legacy audit and therefore will not show the underlying criteria." />}
        </div>}
        controls={controls}
        closeText={closeText}
      >
        <div className="review-summary-modal">
          <div className="themes">
            <h3>
              <span>Overall Grade</span>
              <span className="chip" style={overallGrade}>
                {overallGrade.label || "Ungraded"}
              </span>
            </h3>
            {/**
             * Appendicies
             */}
            {data?.review?.appendices?.length ? (
              <h3
                className={`${selectTheme.id === "actions" ? "active" : ""}`}
                onClick={() => setSelectTheme("actions")}
              >
                <span>Actions/Strengths</span>
                <span className="float-right">
                  {data?.review?.appendices?.length | 0}
                </span>
              </h3>
            ) : (
              ""
            )}

            {/**
             * Criteria and outcomes.
             */}
            {criteria.criteria
              .sort(byIndex)
              .map(({ index, id, name, description, optional }) => {
                const section =
                  data?.review?.responses?.find(
                    ({ criteriaId }) => criteriaId === id
                  ) || {};

                // Resolve grade.

                const grade = section.grade?.name
                  ? {
                    name: section.grade.name,
                    tag: section.grade.name.toLowerCase(),
                    backgroundColor: section.grade.colour,
                  }
                  : { name: optional ? "Optional" : "Ungraded" };

                return (
                  <h3
                    key={index}
                    className={`${selectTheme?.id === id ? "active" : ""}`}
                    onClick={() =>
                      setSelectTheme(
                        criteria.criteria.find(({ id: tid }) => tid === id)
                      )
                    }
                  >
                    <span>{name + ": " + description}</span>
                    <span style={grade} className={`chip chip-${grade.tag}`}>
                      {grade.name}
                    </span>
                  </h3>
                );
              })}
          </div>
          <div className="questions">{renderSectionContent}</div>
        </div>
      </Modal>
    )
  );
};
