import React from "react";
import "./completedModal.scss";

// SVGs.
import { Modal } from "./Modal.jsx";

export default function CompletedModal({
  title = "Audit Submitted",
  audit,
  controls,
}) {
  return (
    <Modal controls={controls} plain={true} size="fitContent">
      <div className="modal_container">
        <div className="content">
          <h3>{title}</h3>
          <p>EPA ID: {audit?.internalId} has been submitted successfully</p>
          <p>
            The PDF audit summary and relevant outcome data are now accessible
            to your local authority coordinator or anyone with 'admin' access.
          </p>
          {/* TODO: Allow to enter emails to notify someone
            <p>**INPUT**</p> */}
        </div>
        <div className="image">
          <img
            alt="Two colleagues collaborating on a computer at thier desk."
            src="https://uploads-ssl.webflow.com/5fa02d6c12a089f71a5e69a2/620d24d3fadbe00751c6137c_pep-2.png"
          />
        </div>
      </div>
    </Modal>
  );
}
