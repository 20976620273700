import React from "react";

export default function TextField({ value, onChange }) {

  return (
    <input 
        type="text" 
        className="text-input" 
        placeholder="Type here..."
        value={value}
        onChange={(e) => onChange(e.target.value)}
     />
  );
}
