import useAPI, { ky } from "./";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

/**
 * Logs in a user with the provided email and password.
 * @param {string} email - The user's email.
 * @param {string} password - The user's password.
 * @returns {Promise<Object>} - A promise that resolves to the response object.
 */
const login = async (email, password) => {
  const res = await ky
    .post("auth/signin", { json: { email, password } })
    .json();
  return res;
};

/**
 * Hook to get the current user's data and provide functions to log out and revalidate the user's data
 * Custom hook for managing user data and authentication.
 * @returns {Object} An object containing user data, logout function, revalidate function, and other properties.
 */
const useUser = () => {
  const { data: user = {}, mutate, ...rest } = useAPI("auth/self", true);

  // Function to log out the current user
  const logout = () => {
    return ky.get("auth/signout").then(() => mutate(null));
  };

  // Function to revalidate the current user's data
  const revalidate = () => {
    mutate(() => true, user, { revalidate: true });
  };

  return { user, logout, revalidate, ...rest };
};

/**
 * Sends a request to reset the user's password.
 *
 * @param {string} email - The email address of the user.
 * @returns {Promise<{ successInd: boolean, email: string }>} - A promise that resolves to an object containing the success indicator and the email address.
 */
const forgotPassword = async (email) => {
  const request = await ky.post("auth/passwordForgot", { json: { email } });
  const { successInd } = await request.json();
  return { successInd, email };
};

/**
 * Checks the validity of a reset token.
 *
 * @param {string} token - The reset token to be checked.
 * @returns {Promise<{ successInd: boolean }>} - A promise that resolves to an object containing the success indicator.
 */
const checkResetToken = async (token) => {
  const request = await ky.post("auth/passwordOTP", { json: { token } });
  const { successInd } = await request.json();
  return { successInd };
};

// Function to check a one-time passcode (OTP)
/**
 * Checks the validity of an OTP (One-Time Password).
 *
 * @param {string} otp - The OTP to be verified.
 * @returns {Promise<{ successInd: boolean, valid: boolean }>} - A promise that resolves to an object containing the success indicator and validity of the OTP.
 */
const checkOTP = async (otp) => {
  const request = await ky.post("auth/verifyOTP", { json: { otp } });
  const { successInd, valid } = await request.json();
  return { successInd, valid };
};

/**
 * Resets the user's password.
 *
 * @param {Object} params - The parameters for resetting the password.
 * @param {string} params.password - The new password.
 * @param {string} params.token - The reset token.
 * @param {string} params.otp - The one-time password.
 * @returns {Promise<Object>} - A promise that resolves to an object containing the success indicator.
 */
const resetPassword = async ({ password, token, otp }) => {
  const request = await ky.post("auth/resetPassword", {
    json: { password, token, otp },
  });
  const { successInd } = await request.json();
  return { successInd };
};

// Function to verify a one-time passcode (OTP)
/**
 * Verifies a one-time passcode (OTP) by sending it to the server.
 * @param {string} otp - The one-time passcode to be verified.
 * @returns {Promise<{ successInd: boolean, tries: number }>} - A promise that resolves to an object containing the success indicator and the number of tries.
 * @throws {Error} - If an error occurs during the verification process.
 */
const verifyOneTimePasscode = async (otp) => {
  try {
    const res = await ky.post("auth/verifyTwoFactorAuthToken", {
      json: { token: otp },
    });
    const response = await res.json();
    const { successInd, tries } = response;
    return { successInd, tries };
  } catch (error) {
    const responseBody = await error.response.json();
    return { successInd: false, tries: responseBody.tries };
  }
};

/**
 * Resends a one-time passcode for two-factor authentication.
 * @param {string} otp - The one-time passcode.
 * @returns {Promise<{ successInd: boolean, tries?: number }>} The result of the resend operation.
 */
const resendOneTimePasscode = async (otp) => {
  try {
    const res = await ky.post("auth/resendTwoFactorAuthOtp");
    const { successInd } = await res.json();
    return { successInd };
  } catch (error) {
    const responseBody = await error.response.json();
    return { successInd: false, tries: responseBody.tries };
  }
};

/**
 * Custom hook for validating the two-factor authentication session.
 * This hook fetches the module name associated with the session and handles redirection to the login page if the session is invalid.
 * @returns {Object} An object containing the `moduleName` associated with the session.
 */
const useValidateTwoFactorAuthSession = () => {
  const navigate = useNavigate();
  const [moduleName, setModuleName] = useState("");

  useEffect(() => {
    const dataFetch = async () => {
      try {
        const res = await ky.get("auth/verifyTwoFactorAuthenticationSession");
        const { moduleName } = await res.json();
        console.log(moduleName);
        setModuleName(moduleName);
      } catch (error) {
        if (error.response?.status === 401) {
          navigate("/login");
          try {
            // check to see if the user has an access token before redirecting to login
            await ky.get("auth/self");
            return;
          } catch (error) {
            if (error.response?.status === 401) {
              toast("incorrect credentials, please login.", {
                type: "error",
              });
            }
          }
        }
      }
    };
    dataFetch();
  }, [navigate]);
  return { moduleName };
};
// Export all functions for use in other modules
export {
  login,
  useUser,
  forgotPassword,
  checkResetToken,
  checkOTP,
  resetPassword,
  verifyOneTimePasscode,
  resendOneTimePasscode,
  useValidateTwoFactorAuthSession,
};
