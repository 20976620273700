import { Button } from "../../Button.jsx";

import "../../../utils/font-family.scss";
import "./ModalForm.scss";

import { Filter } from "../../Filter.jsx";

/**
 * A form for adding and editting the charts block config on report builder.
 */
export const ModalFormCharts = ({
  // Block information, if preset.
  config = {},
  setConfig = () => {},
  formData = {},
}) => {
  return (
    <form className="report-modal-form charts">
      <h2 className="form-header">Grading Graph Block</h2>

      <div className="form-item">
        <label>
          Title:
          <input
            type="text"
            value={config?.title || ""}
            onChange={(e) => {
              setConfig({ ...config, title: e.target.value });
            }}
          ></input>
        </label>
      </div>

      <div className="form-item form-select">
        <label>
          Grading for:
          <div className="select-wrapper">
            <select
              onChange={(e) => {
                setConfig({ ...config, groupBy: e.target.value });
              }}
              value={config.groupBy || ""}
            >
              <option value="">Overall Grading</option>
              {formData.groupBy.map(({ label, value }, key) => {
                return (
                  <option key={key} value={value}>
                    {label}
                  </option>
                );
              })}
            </select>
          </div>
        </label>
      </div>

      <div className="form-item form-checkbox">
        <label>
          <input
            type="checkbox"
            checked={config?.legend || false}
            onChange={(e) => {
              setConfig({ ...config, legend: e.target.checked });
            }}
          />
          Display detailed graph data
        </label>
      </div>

      <div className="form-item filters">
        <Button
          type="primary"
          onClick={() => {
            // Grab and add to filters.
            const filters = config?.filters || [];
            filters.push({});

            // Set state.
            setConfig({
              ...config,
              filters,
            });
          }}
        >
          Add Filter
        </Button>

        {config?.filters?.length
          ? config.filters.map((filter, index) => {
              return (
                <Filter
                  key={index}
                  config={formData.filters}
                  value={filter}
                  onChange={(val) => {
                    // Update filters
                    const filters = config.filters;
                    filters[index] = val;

                    // Save.
                    setConfig({
                      ...config,
                      filters,
                    });
                  }}
                  onRemove={() => {
                    setConfig({
                      ...config,
                      filters: [
                        ...config.filters.slice(0, index),
                        ...config.filters.slice(index + 1),
                      ],
                    });
                  }}
                />
              );
            })
          : ""}
      </div>
    </form>
  );
};
