/**
 * A form for adding and editting the comments block config on report builder.
 */

export const ModalFormComments = ({
  // Block config.
  config,
  setConfig,

  // Reference data
  formData = [],
  context,

  // Date Formatter
  formatToDate = (d) => d,
}) => {
  return (
    <form className="form-settings">
      <h1 className="title">Comments Block </h1>
      <div className="form-setting-item inline-flex">
        <label className="label">Title:</label>{" "}
        <input
          value={config.title}
          className="form-settings-title-text"
          type="text"
          onChange={(e) => {
            setConfig((s) => ({ ...s, title: e.target.value }));
          }}
        />
      </div>
      <section className="inline-flex form-settings-section-picker">
        <label className="form-settings-section-title label">Theme</label>
        <select
          className="form-settings-section-menu"
          value={config?.section?.value}
          onChange={(e) => {
            console.log("Change selected");
            const section = context.criteria.findIndex(
              ({ name, description }) =>
                `${name} | ${description}` === e.target.value
            );
            setConfig({
              ...config,
              section: {
                value: e.target.value,
                id: context.criteria[section].id,
              },
              title: e.target.value,
            });
          }}
        >
          {context?.criteria?.map((el, index) => (
            <option key={index}>{`${el.name} | ${el.description}`}</option>
          ))}
        </select>
      </section>
      <section className="form-settings-item date-picker">
        <section className="date-picker-item">
          <label className="label">Completed between:</label>
        </section>

        <section className="date-picker-item-input">
          <input
            value={formatToDate(config?.from)}
            className="form-settings-date-picker from"
            type="date"
            onChange={(e) => setConfig({ ...config, from: e.target.value })}
          />

          <svg
            className="date-picker-arrow"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M14 5l7 7m0 0l-7 7m7-7H3"
            ></path>
          </svg>
          <input
            value={formatToDate(config?.to)}
            className="form-settings-date-picker to"
            type="date"
            onChange={(e) => setConfig({ ...config, to: e.target.value })}
          />
        </section>
      </section>
      {/* <section className="inline-flex form-settings-list-questions ">
        <input
          checked={config.showThemeComments}
          className="form-settings-list-item-checkbox"
          type="checkbox"
          onChange={(e) =>
            setConfig({
              ...config,
              showThemeComments: e.target.checked,
            })
          }
        />
        <h2> Display Theme Comments</h2>
      </section> */}

      {/* <section className="inline-flex form-settings-list-questions ">
        <input
          checked={config.showQuestionComments}
          className="form-settings-list-item-checkbox"
          type="checkbox"
          onChange={(e) =>
            setConfig({
              ...config,
              showQuestionComments: e.target.checked,
            })
          }
        />
        <h2> Display Question Comments</h2>
      </section>

      <section className="question-comments border">
        <section className="form-settings-list">
          {block.explanatoryComments.length > 0 &&
          config.showQuestionComments
            ? block.explanatoryComments.map((el, index) => (
                <section className="form-settings-list-item">
                  <input
                    data-question={el[0]}
                    className="form-settings-list-item-checkbox"
                    type="checkbox"
                    checked={el[2]}
                    onChange={(e) => {
                      const question = e.target.getAttribute("data-question");

                          const blockIndex =
                            block.explanatoryComments.findIndex(
                              (el) => el[0] === question
                            );

                          const cpBlock = [...block.explanatoryComments];
                          const changeBlock = cpBlock[blockIndex];
                          changeBlock[2] = e.target.checked;
                          cpBlock[blockIndex] = changeBlock;

                          setBlock({
                            ...block,
                            explanatoryComments: cpBlock,
                          });

                      setConfig({
                        ...config,
                        questions: cpBlock,
                      });
                    }}
                  />
                  <label>{el[0]}</label>
                </section>
              ))
            : ""}
        </section>
      </section> */}
    </form>
  );
};
