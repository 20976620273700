import kyNPM from "ky";
import useSWR, { useSWRConfig } from "swr";
import { toast } from "react-toastify";

import { APIURL } from "../config";

function useGlobalMutate() {
  const { mutate } = useSWRConfig();
  return mutate;
}

let ky = kyNPM.create({
  prefixUrl: APIURL,
  credentials: "include",
  headers: {
    "x-module-id": process.env.REACT_APP_MODULE_ID,
  },
});

function useAPI(url, allow401 = false) {
  const {
    data, //: data for the given key resolved by fetcher (or undefined if not loaded)
    error, //: error thrown by fetcher (or undefined)
    isValidating, //: if there's a request or revalidation loading
    mutate, //(data?, shouldRevalidate?): function to mutate the cached data (details)
  } = useSWR(
    url,
    (url) =>
      ky
        .get(url)
        .then((a) => a.json())
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            if (allow401 && window.location.href.includes("/login"))
              return null;
            console.warn("Unauthorized");
            return null;
          }

          console.error("API Error", error);
          try {
            error.response
              .json()
              .then(({ message }) =>
                toast.warn("Something unexpected happened: " + message)
              )
              .catch(() =>
                toast.warn(
                  "Something unexpected happened, please try again in a few minutes"
                )
              );
          } catch (e) {
            console.error(e);
            // If have an error, and we're not on an error page, redirect to the down page.
            if (window.location.pathname.indexOf("error") === -1) {
              window.location = "/error/down";
            }
          }
          return null;
        }),
    {
      suspense: true,
      revalidateOnMount: true,
    }
  );

  return { data, error, loading: isValidating, mutate };
}

export { useAPI as default, useGlobalMutate, ky, APIURL as apiUrl };
