import { useEffect, useState } from "react";
import { Modal } from "./Modal.jsx";

import "./AuditSummaryModal.scss";
import { useMemo } from "react";

function ThemeQuestions({ section: { grade, comment, ...section }, themes }) {
  const theme = themes.find(({ id }) => id === section.id);

  return (
    <>
      <h4>{theme.name}</h4>
      {comment && (
        <p>
          Comments on this theme: <i>{comment}</i>
        </p>
      )}
      <ul>
        {theme.questions.map(({ title, id, options = [] }, index) => {
          const response = section[id] || {};

          return (
            <li key={id}>
              <h5>Question {index + 1}</h5>
              <p>{title}</p>
              <div>
                {response.value
                  ? options?.find(({ value }) => value === response.value)
                      ?.label || response.value
                  : "No Response"}
                {response.comment && (
                  <>
                    {" "}
                    with comment: <i>{response.comment}</i>
                  </>
                )}
              </div>
            </li>
          );
        })}
      </ul>
    </>
  );
}

/**
 * Parses the outcome monitoring questions and answers.
 *
 * @param {*} data - outcome monitoring object from the DB.
 *
 * @returns - Questions array.
 */
const parseOutcomeMonitorng = (data) => {
  if (data.longTermReview || data.qty) {
    const outcomes = data;
    const outcomesArray = Object.entries(outcomes)
      .filter(
        ([key]) =>
          !["longTermReview", "qty"].includes(key) && key.includes("broad-area")
      )
      .map(([key, { value: category }]) => [
        category,
        outcomes[key.split("-")[0] + "-outcome"].value,
      ]);
    data = Object.fromEntries(outcomesArray);
  }

  const definitions = {
    areas: {
      cl: "C&L",
      semh: "SEMH",
      seph: "SE/PH",
      ci: "C&I",
      emp: "EMP",
      il: "IL",
      scin: "SC/IN",
      hw: "H&W",
    },
    answers: [
      { value: "achieved", label: "Has achieved target" },
      { value: "likelyAchieve", label: "Is likely to achieve target" },
      { value: "suceeded", label: "Has exceeded target" },
      { value: "likelySucceed", label: "Likely to exceed target" },
      {
        value: "partiallyAchieved",
        label: "Has partially achieved target",
      },
      {
        value: "onTrackPartiallyAchieve",
        label: "Is on track to partially achieve target",
      },
      { value: "notAchieved", label: "Has not achieved target" },
      {
        value: "notOnTrackAchieve",
        label: "Is not on track to achieve target",
      },
    ],
  };

  let questions = [];

  questions.push({
    title:
      "Is there evidence that the outcomes within the child's PEP were reviewed?",
    answer: Object.keys(data)?.length > 0 ? "Yes" : "No",
  });

  questions.push({
    title: "How many PEP outcomes were reviewed?",
    answer: Object.keys(data)?.length || 0,
  });

  if (Object.keys(data)?.length >= 0) {
    Object.entries(data).forEach(([title, answer], _index) => {
      questions.push({
        title: definitions.areas[title] || "",
        answer:
          definitions.answers.find(({ value }) => value === answer)?.label ||
          "",
      });
    });
  }

  return questions;
};

const OutcomeMonitoringQuestions = ({ responses }) => {
  const questionsToRender = parseOutcomeMonitorng(responses);

  return (
    <>
      <h4>Outcomes Monitoring</h4>
      <ul>
        {questionsToRender.length &&
          questionsToRender.map(({ title, answer }) => {
            return (
              <li>
                <p>{title}</p>
                <div>{answer}</div>
              </li>
            );
          })}
      </ul>
    </>
  );
};

export const AuditSummaryModal = ({
  data,
  controls,
  sectionId = 1,
  themes: themeDefinitions,
}) => {
  // Theme we're currently viewing.
  const [selectTheme, setSelectTheme] = useState(1);
  useEffect(() => {
    setSelectTheme(sectionId);
  }, [sectionId]);

  // Pane content memo.
  const renderSectionContent = useMemo(() => {
    switch (selectTheme) {
      case "actions":
        return (
          <>
            {!!data?.strengths && (
              <>
                <h4>Strengths</h4>
                <ul className="actions-list" style={{ marginBottom: "8px" }}>
                  <li>{data?.strengths}</li>
                </ul>
              </>
            )}
            <h4>Actions</h4>
            <ul className="actions-list">
              {data?.actions?.map((action, index) => (
                <li key={index}>{action}</li>
              ))}
            </ul>
          </>
        );
      case "outcomeMonitoring":
        return (
          <OutcomeMonitoringQuestions responses={data.outcomeMonitoring} />
        );
      default:
        return (
          <ThemeQuestions
            section={{ ...data.sections[selectTheme], id: selectTheme }}
            themes={themeDefinitions}
          />
        );
    }
  }, [data, selectTheme, themeDefinitions]);

  return (
    themeDefinitions &&
    data && (
      <Modal title={`PEP Review - ${data.pepId}`} controls={controls}>
        <div className="review-summary-modal">
          <div className="themes">
            <h3>
              <span>Overall Grade</span>
              <span className={`chip chip-${data.outcome}`}>
                {data.outcome || "Not Started"}
              </span>
            </h3>
            {themeDefinitions.map(({ id, name }, index) => {
              const section = data.sections[id] || {};
              const grade = section.grade
                ? section.grade.charAt(0).toUpperCase() + section.grade.slice(1)
                : "Not started";
              return (
                <h3
                  key={index}
                  className={`${selectTheme === id ? "active" : ""}`}
                  onClick={() => setSelectTheme(id)}
                >
                  <span>{"Theme " + id + ": " + name}</span>
                  <span className={`chip chip-${section.grade}`}>{grade}</span>
                </h3>
              );
            })}
            {data?.outcomeMonitoring ? (
              <h3
                className={`${
                  selectTheme === "outcomeMonitoring" ? "active" : ""
                }`}
                onClick={() => setSelectTheme("outcomeMonitoring")}
              >
                <span>
                  Theme 6:
                  <br />
                  Outcome Monitoring
                </span>
                <span className={`chip chip-${data.outcomeMonitoring.grade}`}>
                  {data.outcomeMonitoring.grade || "Not Started"}
                </span>
              </h3>
            ) : (
              ""
            )}
            {data?.actions?.length ? (
              <h3
                className={`${selectTheme === "actions" ? "active" : ""}`}
                onClick={() => setSelectTheme("actions")}
              >
                <span>Actions/Strengths</span>
                <span className="float-right">{data?.actions?.length | 0}</span>
              </h3>
            ) : (
              ""
            )}
          </div>
          <div className="questions">{renderSectionContent}</div>
        </div>
      </Modal>
    )
  );
};
