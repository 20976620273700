import { format } from "date-fns";

export const formatToDate = (date) => {
  return date ? format(new Date(date), "dd-MM-yyyy") : "";
};
/* Enum for block types. */
export const GridBlocksEnum = {
  CUSTOM_TEXT: "CUSTOM_TEXT",
  COMMENTS: "COMMENTS",
  CUSTOM_CHART: "CUSTOM_CHART",
  BLOCK_ADD: "BLOCK_ADD",
};

/* Blocks without the config option, hides edit and add modal and ctas. */
export const ConfiglessBlocks = [GridBlocksEnum.CUSTOM_TEXT];

/* Titles for blocks (ommitting shows no title bar). */
export const GridBlockTitles = {
  CUSTOM_TEXT: "Custom Text",
  COMMENTS: "Comments",
  CUSTOM_CHART: "Custom Chart",
};

export const defaultConfig = (type, module) => {
  switch (type) {
    case GridBlocksEnum.COMMENTS:
      const { id, name, description } = module.criteria[0]
      return {
        section: { 
          id,
          value: `${name}. ${description}`
        },
        to: Date.now(),
        from: new Date("08/09/2020").getTime(),
        showThemeComments: true,
        showQuestionComments: false,
        title: `${name}. ${description}`
      };
    case GridBlocksEnum.CUSTOM_CHART:
      return {
        type: "bar",
        data: [],
        title: "Custom Title",
        showThemeComments: false,
        showQuestionComments: false,
        legend: true
      };
    default:
      throw new Error("no option selected ");
  }
};

// works out if there is any content with render
// here we can handle different cases for different blocks

export const noRender = (type, config) => {
  switch (type) {
    case GridBlocksEnum.COMMENTS:
      return (
        config.showQuestionComments || config.showThemeComments
      );

    case GridBlocksEnum.CUSTOM_CHART:
      return !!type && !!config;

    default:
      return false;
  }
};
