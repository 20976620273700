import ReactModal from "react-modal";
import Button from "./Button.jsx";
import { useModalControls } from "./Modal.jsx";
import "./confirm.scss";

/**
 * Controls hook for confirm. Same methods as modal.
 */
export const useConfirmControls = useModalControls;

export const Confirm = ({
  /* A controls object from modal controls hook - default to bare minimum. */
  controls = {
    isOpen: false,
    open: () => {},
    close: () => {},
  },

  /* Content. */
  children = <></>,

  /* Confirm handler. */
  onConfirm = () => {},

  /* Type - Based on button type, controls confirm appearance. */
  type = "primary",

  /* Title. */
  title = "",
}) => {
  return (
    <ReactModal isOpen={controls.isOpen} className="confirm-modal">
      <div className="modal-header">
        <h3>{title}</h3>
      </div>

      <div className="modal-content">{children}</div>

      <div className="modal-footer">
        <div className="modal-buttons">
          <Button type={type} onClick={onConfirm}>
            Confirm
          </Button>
          <Button type="secondary" onClick={controls.close}>
            Cancel
          </Button>
        </div>
      </div>
    </ReactModal>
  );
};
