import React, { useEffect, useMemo, useState } from "react";
import "./AuditSummary.scss";
import tick from "./assets/tick.png";
import plus from "./assets/plus.png";
import { ReactComponent as NotCompleted } from "./assets/notCompleted.svg";
import { useCallback } from "react";
import { v4 } from "uuid";
import { useDebouncedCallback } from "use-debounce";
import CommentBox from "./CommentBox";

export default function AuditSummary({
  outcomes = [],
  isValidating,
  validate,
  criteria,
  grades,
  audit,
  module,
  overall,
  actionsList = [],
  onChange = () => {},
}) {
  const [strengths, setStrengths] = useState(
    actionsList.find((a) => a.name === "strength") || {
      id: v4(),
      name: "strength",
      value: "",
    }
  );

  // List of actions.
  const [actions, setActions] = useState(
    actionsList
      .filter((a) => a.name === "action")
      ?.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt)) || [
      { value: "" },
    ]
  );

  // Action input.
  const [action, setAction] = useState("");

  // Bubble changes to parent.
  const updateActions = useCallback(
    () => onChange({ actions: [...actions, strengths] }),
    [actions, onChange, strengths]
  );
  const updateStrengths = useDebouncedCallback(updateActions, 300);

  const handleUpdateComment = (value) => {
    setStrengths((s) => {
      const str = ({ ...s, value });
      updateStrengths(str);
      return str;
    });
  };

  useEffect(() => {
    if (actions !== actionsList.filter((a) => a.name === "action"))
      updateActions();
    /* eslint-disable-next-line */
  }, [actions]);

  // checks every section is complete
  const validCriteria = useMemo(() => {
    return criteria.map(({ id, questions, optional, ...rest }) => {
      const currentResponse = audit.responses?.find((r) => r.criteriaId === id);
      const valid = validate(
        currentResponse?.responseQuestion.map((rq) => ({
          ...rq,
          answerId: rq.questionAndAnswers?.id,
          inputValue: rq.questionAndAnswers?.options?.value,
        })),
        questions,
        optional
      );
      return { id, currentResponse, optional, ...rest, valid };
    });
  }, [criteria, audit, validate]);

  const isCompleted = useMemo(
    () => !validCriteria.some((v) => !v.valid),
    [validCriteria]
  );
  /**
   * Submits an action, clearing the input and adding it to the actions array.
   */
  const submitAction = useCallback(() => {
    if (action) {
      const value = action.trim().slice(0, 2000);
      const newAction = { id: v4(), action: "add", name: "action", value };
      setActions((state) => [...state, newAction]);
      setAction("");
    }
  }, [action]);

  /**
   * Reverse an action, pulling into the input and
   * removing it from the array.
   */
  const reverseAction = useCallback(
    (e) => {
      const currentActions = actions?.filter((a) => a.action !== "remove");
      if (!action && currentActions?.length) {
        e.preventDefault();
        const lastEntry = currentActions[currentActions.length - 1];
        const newActions = [
          ...currentActions.slice(0, -1),
          { ...lastEntry, action: "remove" },
        ];
        setActions(newActions);
        setAction(lastEntry.value);
      }
    },
    [action, actions]
  );
  /**
   * changed actions header based on module
   */
  const strengthsHeader = useMemo(() => {
    switch (module) {
      case "AR":
        return `Strengths of ${module}`;
      case "QEPA":
        return `Strengths of EP Advice`;
      default:
        return `Strengths of ${module}`;
    }
  }, [module]);

  /**
   * Removes an action.
   */
  const removeAction = useCallback(
    (index) => {
      const newActions = [
        { ...actions[index], action: "remove" },
        ...actions.slice(0, index),
        ...actions.slice(index + 1),
      ];
      setActions(newActions);
    },
    [actions, setActions]
  );

  return (
    <div className="audit-summary">
      <div className="summary">
        <div className="overall-row">
          <span className="overall-text">
            The overall grade for this plan, based on your selections:
          </span>
          <div className="grade">
            <p>{overall && isCompleted ? overall?.name : "Not completed"}</p>
            <span
              style={{
                backgroundColor: isCompleted ? overall?.colour : "transparent",
              }}
              className="chip"
            >
              {!isCompleted && <NotCompleted />}
            </span>
          </div>
        </div>
        <div className="sections-grading">
          {validCriteria.map(
            ({ id, name, description, currentResponse, valid, optional }) => {
              let grade = null;
              if (!optional && valid)
                grade = grades.find((g) => g.id === currentResponse?.gradeId);
              if (optional)
                grade = {
                  name: "Optional",
                  colour: "#F6F6F6",
                };
              return (
                <div
                  className={`theme-row ${
                    !grade && isValidating ? "incomplete" : ""
                  }`}
                  key={id}
                >
                  <h6>{name}</h6>
                  <span className="theme-name">{description}</span>
                  <div className="grade fixed-grade">
                    {grade ? <p>{grade.name}</p> : <p>Not completed*</p>}
                    <span
                      className="chip"
                      style={{
                        backgroundColor: grade?.colour || "transparent",
                      }}
                    >
                      {!grade && <NotCompleted />}
                    </span>
                  </div>
                </div>
              );
            }
          )}
        </div>
      </div>

      <div className="actions">
        <h4>{strengthsHeader}</h4>
        <div className="action-row">
          <CommentBox
            comment={strengths.value}
            placeholder="Please write the strengths for this audit"
            updateComment={handleUpdateComment}
          />
        </div>
      </div>

      <div className="actions">
        <h4>Set Audit Actions</h4>
        {actions.map(
          ({ action, value }, id) =>
            action !== "remove" && (
              <div className="action-row" key={id}>
                <img className="tick" src={tick} alt="Tick mark" />
                <img
                  className="cross"
                  src={plus}
                  alt="Cross symbol"
                  onClick={() => removeAction(id)}
                />
                <p>{value}</p>
              </div>
            )
        )}
        <div className="action-row">
          <img src={plus} alt="Plus symbol" onClick={() => submitAction()} />
          <input
            id=""
            maxlength="2000"
            type="text"
            value={action}
            onChange={({ target: { value } }) =>
              setAction(value.slice(0, 2000))
            }
            onKeyDown={(evt) => {
              switch (evt.code) {
                // On enter, we should check for submit
                case "Enter":
                  submitAction();
                  break;

                // On backspace, check if we're empty and reverse.
                case "Backspace":
                  reverseAction(evt);
                  break;

                default:
              }
            }}
          ></input>
        </div>
      </div>
    </div>
  );
}
