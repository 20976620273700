import { useEffect, useState } from "react";

import "./FormToggle.scss";

const FormToggle = ({
  options = [],
  default: defaultValue = null,
  onChange = () => {},
}) => {
  // Value state.
  const [value, setValue] = useState(defaultValue);

  // Watch and bubble events.
  useEffect(() => {
    onChange(value);
  }, [value, onChange]);

  return (
    <div className="form-toggle">
      <div className="buttons">
        {options.map(({ value: opval, label }) => (
          <button
            className={opval === value ? "primary" : "secondary"}
            onClick={() => {
              setValue(opval);
            }}
          >
            {label}
          </button>
        ))}
      </div>
    </div>
  );
};

export { FormToggle };
