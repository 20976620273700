import React from "react";
import "./ViewGradingFlow.scss";
import gradingFlowImage from "./assets/gradingflow-qepa.jpg";

import { Modal, useModalControls } from "./Modal.jsx";

function ViewGradingFlow() {
  const controls = useModalControls();

  return (
    <div className="gradingflowimage">
      <button
        className="gradingflowimage__button"
        onClick={() => controls.open()}
      >
        &#9432; How do I choose the correct criteria?
      </button>
      <Modal controls={controls} plain={true} size="grading">
        <div className="gradingflowimage__container">
          <img
            alt="A flowchart showing how your critera selections will impact overall grading."
            className="gradingflowimage__image"
            src={gradingFlowImage}
          />
        </div>
      </Modal>
    </div>
  );
}

export default ViewGradingFlow;
