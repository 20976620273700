import "./MultipleChoiceQuestion.scss";
import Checkbox from "./Checkbox";
import { useState } from "react";

export default function CheckboxQuestion({
  name,
  options,
  value = {},
  onChange: _onChange,
  className = "",
  notGraded,
}) {
  const currentValue = value.answerId
    ? options?.find((a) => a.id === value?.answerId)
    : options?.find((a) => a.options?.value === value?.value);
  const commentsShowing = currentValue?.allowcomment;
  const [checked, setChecked] = useState(!!currentValue?.options?.value);
  const onChange = (value) => {
    _onChange({ ...value, notGraded });
    setChecked(!checked);
  };

  return (
    <div className={"multiple-choice-question question " + className}>
      <div className="base">
        <Checkbox
          label={name}
          onChange={() => {
            onChange({
              comment: value?.comment,
              value: !checked ? "true" : false,
            });
          }}
          checked={checked}
        />
      </div>
      <div className={"expandable" + (commentsShowing ? " visible" : "")}>
        <textarea
          rows="3"
          placeholder="Type your reasoning here"
          onChange={({ target: { value: comment } }) => {
            if (value.comment !== comment) onChange({ ...value, comment });
          }}
          value={value.comment || ""}
        />
      </div>
    </div>
  );
}
