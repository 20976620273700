import { useState } from "react";

import "./accordion.scss";

export default function Accordion({ title, colour, openByDefault, children }) {
  const [open, setOpen] = useState(openByDefault);

  return (
    <div className={`accordion ${open ? "is-open" : ""}`}>
      <div
        className="accordion-header"
        style={{backgroundColor: colour}}
        onClick={() => {
          setOpen(!open);
        }}
      >
        <h3>{title}</h3>
      </div>
      <div className="accordion-content">
        <div className="accordion-content__overflow">
          {children}
        </div>
      </div>
    </div>
  );
}

export { Accordion };
