import "./ReportBlock.scss";

/* Tiptap */
import { useEditor, EditorContent } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Placeholder from "@tiptap/extension-placeholder";

/* Icons */
import { ReactComponent as UlSvg } from "../../assets/custom-text-ul.svg";

/* React */
import { useState } from "react";

const ToolBar = ({ editor }) => {
  if (!editor) return "";

  return (
    <div className="title-bar">
      <button
        onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
        className={editor.isActive("heading", { level: 1 }) ? "is-active" : ""}
      >
        H1
      </button>
      <button
        onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
        className={editor.isActive("heading", { level: 2 }) ? "is-active" : ""}
      >
        H2
      </button>
      <button
        onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
        className={editor.isActive("heading", { level: 3 }) ? "is-active" : ""}
      >
        H3
      </button>
      <button
        onClick={() => editor.chain().focus().toggleHeading({ level: 4 }).run()}
        className={editor.isActive("heading", { level: 4 }) ? "is-active" : ""}
      >
        H4
      </button>
      <button
        onClick={() => editor.chain().focus().setParagraph().run()}
        className={editor.isActive("paragraph") ? "is-active" : ""}
      >
        Text
      </button>
      <button
        onClick={() => editor.chain().focus().toggleBold().run()}
        disabled={!editor.can().chain().focus().toggleBold().run()}
        className={editor.isActive("bold") ? "is-active" : ""}
      >
        B
      </button>
      <button
        onClick={() => editor.chain().focus().toggleBulletList().run()}
        className={editor.isActive("bulletList") ? "is-active" : ""}
      >
        <UlSvg />
      </button>
    </div>
  );
};

function ReportBlockCustomText({ item, updateBlock }) {
  const [value, setValue] = useState(item?.content || {});

  /* TipTap hook. */
  const editor = useEditor({
    extensions: [
      StarterKit,
      Placeholder.configure({ placeholder: "Type your text here..." }),
    ],
    content: value,
    onUpdate: ({ editor }) => {
      const currVal = {
        json: editor.getJSON(),
        html: editor.getHTML(),
      };
      if (currVal) setValue(currVal.json);
      if (currVal)
        updateBlock({
          ...item,
          content: currVal.html,
          config: currVal.json,
        });
    },
  });

  return (
    <div className="report-block custom-text">
      <ToolBar editor={editor} />
      <EditorContent className="textarea" editor={editor} />
    </div>
  );
}

export { ReportBlockCustomText };
