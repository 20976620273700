import React from "react";
import "./forminput.scss";

/**
 * Primary UI component for user interaction
 *
 * @param {'text'|'number'|'email'|'password'} type - The input field type.
 * @param {string} label - The input field label.
 * @param {string} [value] - The default value.
 * @param {boolean} [required=false] - Makes the input required.
 * @param {boolean} [disabled=false] - Disables the input.
 * @param {boolean} [inline=false] - Shows the label and input side-by-side.
 * @param {(value) => void} [onChange] - OnChange handler method, called when value is changed.
 */
export default function FormInput(args) {
  // Deconstruct args.
  const {
    /* Attributes. */
    type,
    label,
    name = "",
    required = false,
    disabled = false,
    inline = false,
    value = undefined,
    full = false,
    atop = false,

    /* Actions. */
    onChange = (value) => {},
    ...rest
  } = args;

  // Normalise label or use name.
  const attrname = name
    ? name
    : label?.toString().toLowerCase().replace(/\s+/g, "-");

  return (
    <label className={atop ? "atop" : ""}>
      <span>{label}</span>
      <input
        name={attrname}
        type={type}
        className={`forminput${inline ? " inline" : ""}${full ? " full" : ""}`}
        required={required}
        disabled={disabled}
        value={value}
        onChange={(evt) => onChange(evt.target.value)}
        {...rest}
      ></input>
    </label>
  );
}
