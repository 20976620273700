import { default as useAPI, ky } from "./";
// import { useDebouncedCallback } from "use-debounce";
import { useState } from "react";
import { toast } from "react-toastify";

export function useAssignmentList() {
  const { data, ...rest } = useAPI("audit/reviews-list");
  const { audits = [], assignments = [] } = data || {};
  return {
    audits: audits.map(({ plan, ...audit }) => ({ ...plan, ...audit })),
    assignments: assignments.map(({ plan, ...assignment }) => ({
      ...plan,
      ...assignment,
    })),
    ...rest,
  };
}

export async function createAudit(assignmentId) {
  if (!assignmentId) return null;
  return ky.post("audit/create-review/" + assignmentId).json();
}

export async function submitAudit(reviewId) {
  if (!reviewId) return null;
  return ky
    .post("audit/submit-review/" + reviewId)
    .json()
    .catch((e) => console.error(e));
}

export function useRemoteAudit(auditId) {
  const {
    data,
    error,
    loading,
    isValidating,
    mutate: swrMutate,
  } = useAPI(auditId ? "audit/review/" + auditId : null);
  let [requested, setRequested] = useState(false);

  const updateRemote = (data) => {
      ky.post("audit/review/" + auditId, { json: data })
        .then(() => swrMutate())
        .catch((e) => {
          console.error(e);
          swrMutate();
        })
        .finally(() => setRequested(false));
    }


  function mutate(delta) {
    if (!auditId) return;

    const { criteriaId, actions, gradeId, comment, answer, completed } = delta

    if (criteriaId === "summary")
      swrMutate(
        {
          ...data,
          appendices: actions?.filter((a) => a.action !== "remove"),
          completed
        },
        false
      );
    else {

      const updateAnswer = (answer) => {
        const { questionId, index, answerId, gradeId, comment, value } = answer

        // Get question response index
        const isResponseToQuestion = (r) => index ? (questionId === r.questionId) : (questionId === r.questionId && r.index === index)
        const QuestionIndex = section.responseQuestion?.findIndex(isResponseToQuestion)
        
        // Set question
        let question = {};

        if (QuestionIndex >= 0) question = section.responseQuestion[QuestionIndex]
        if (index) question = { ...question, index }

        question = { ...question, questionId, answerId, gradeId, comment, value }

          // Construct responseQuestion
          const responseQuestion = section.responseQuestion || []
          if (QuestionIndex < 0) responseQuestion.splice(responseQuestion.length, 0, question);
          else responseQuestion.splice(QuestionIndex, 1, question);

        return responseQuestion
      }
      // Get section
      const index = data?.responses.findIndex(
        (r) => r.criteriaId === criteriaId
      );
      const section =
        index >= 0
          ? data.responses[index]
          : { criteriaId, reviewId: data.id };

      // response
      let response = {
        ...section,
        gradeId,
        comment
      };

      if (answer) {
        const responseQuestion = updateAnswer(answer)

        // Updated response
        response = { ...response, responseQuestion };
      }
      const responses = [
        ...data.responses.slice(0, index),
        response,
        ...data.responses.slice(index + 1),
      ];
      swrMutate({ ...data, responses }, false);
    }

    // Update on the db
    updateRemote({ sections: delta });
    setRequested(true);
  }

  return {
    audit: data || {},
    error,
    loading,
    isValidating,
    mutate,
    requested,
  };
}

export async function getToolData() {
  try {
    const request = await ky.get("audit/module");
    return await request.json();
  } catch (error) {
    console.log(error);
    // Silence unauthorized calls for when we're unauthed.
    if (error.response.status === 401) return;
    toast("Unable to view criteria. Please try again later.", {
      type: "warning",
    });
    return {
      count: 0,
      rows: [],
    };
  }
}

export async function getGrades(grades) {
  try {
    const res = await ky.get("audit/grades", {
      searchParams: {
        grades,
      },
    });
    return await res.json();
  } catch (error) {
    console.log(error);
    // Silence unauthorized calls for when we're unauthed.
    if (error.response.status === 401) return;
    toast("Unable to view Grades. Please try again later.", {
      type: "warning",
    });
    return {
      count: 0,
      rows: [],
    };
  }
}

export const getFurtherDataList = async (pagination) => {
  try {
    const grades = await getGrades(process.env.REACT_APP_GRADES);

    const request = await ky.get("audit/further", {
      searchParams: pagination,
    });
    const furtherData = await request.json();

    return {
      ...furtherData,
      grades,
    };
  } catch (error) {
    console.log(error);
    toast("Unable to retrieve further data. Please try again later.", {
      type: "warning",
    });
    return {
      count: 0,
      rows: [],
    };
  }
};

export const useCriteriaData = () => {
  const { data } = useAPI("audit/module");
  return data;
};
