import TextField from "./TextField";
import "./TextQuestion.scss";

export default function TextQuestion({
  title,
  value = {},
  onChange,
  className = "",
  notGraded,
}) {
  const commentsShowing = value?.value === "partial";

  return (
    <div className={" question " + className}>
      <div className="base">
        <h3>{title}</h3>
        <span>
          <TextField
            value={value.value}
            onChange={(v) => onChange({ value: v, notGraded })}
          />
        </span>
      </div>
      <div className={"expandable" + (commentsShowing ? " visible" : "")}>
        <textarea
          rows="3"
          placeholder="Type your reasoning here"
          onChange={({ target: { value: comment } }) => {
            if (value.comment !== comment)
              onChange({ ...value, comment, notGraded });
          }}
          value={value.comment || ""}
        />
      </div>
    </div>
  );
}
