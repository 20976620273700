import { ReactComponent as Theme1 } from "../stories/assets/navIcons/statutory-requirements.svg";
import { ReactComponent as Theme2 } from "../stories/assets/navIcons/voice-cyp.svg";
import { ReactComponent as Theme3 } from "../stories/assets/navIcons/attainment-progress.svg";
import { ReactComponent as Theme4 } from "../stories/assets/navIcons/other-areas-need.svg";
import { ReactComponent as Theme5 } from "../stories/assets/navIcons/targets-transition-planning.svg";
import { ReactComponent as Theme6 } from "../stories/assets/navIcons/outcome-monitor.svg";
// import { ReactComponent as Provision } from "../stories/assets/navIcons/provision.svg";
import { ReactComponent as CurrentAssessment } from "../stories/assets/navIcons/current-assessment.svg";
import { ReactComponent as PersonCentred } from "../stories/assets/navIcons/person-centred.svg";
import { ReactComponent as EpAdvice } from "../stories/assets/navIcons/ep-advice.svg";
import { ReactComponent as ValueAdded } from "../stories/assets/navIcons/value-added.svg";
import { ReactComponent as AdditionalComponents } from "../stories/assets/navIcons/additional-components.svg";
import { ReactComponent as Summary } from "../stories/assets/navIcons/summary.svg";
import { ReactComponent as Icon } from "../stories/assets/user_profile.svg";
import { ReactComponent as UploadAuditsSVG } from "../stories/assets/navIcons/upload-audits.svg";
import { ReactComponent as UserManagementSVG } from "../stories/assets/navIcons/user-management.svg";
import { ReactComponent as AssignReviewsSVG } from "../stories/assets/navIcons/assign-reviews.svg";
import { ReactComponent as ReviewsSVG } from "../stories/assets/navIcons/view-reviews.svg";
import { ReactComponent as LiveReviewsSVG } from "../stories/assets/navIcons/live-reviews.svg";
import { ReactComponent as ModeratedReviewsSVG } from "../stories/assets/navIcons/moderated-reviews.svg";
import { ReactComponent as DashboardSVG } from "../stories/assets/navIcons/dashboard.svg";
import { ReactComponent as ExportSVG } from "../stories/assets/navIcons/export.svg";
import { ReactComponent as QueryBuilderSVG } from "../stories/assets/navIcons/query-builder.svg";
import { ReactComponent as FurtherDataSVG } from "../stories/assets/navIcons/further-data.svg";
import { ReactComponent as ThemeCommentsSVG } from "../stories/assets/navIcons/theme-comments.svg";
import { ReactComponent as OutcomesMonitorSVG } from "../stories/assets/navIcons/outcomes-monitor.svg";

export default function getSvg(id) {
  switch (id) {
    case "timeliness-&-quality":
      return <Theme1 />;
    case "meeting-needs":
      return <Theme2 />;
    case "outcomes":
      return <Theme3 />;
    case "provision":
      return <Theme4 />;
    case "post-annual-review":
      return <Theme5 />;
    case "outcomes-monitoring":
      return <Theme6 />;
    case "summary":
      return <Summary />;
    case "returned-within-6-weeks-of-request":
      return <EpAdvice />;
    case "background-&-timeliness":
      return <Theme1 />;
    case "accessible":
      return <Theme2 />;
    case "current-assessment":
      return <CurrentAssessment />;
    case "person-centred":
      return <PersonCentred />;
    case "value-added":
      return <ValueAdded />;
    case "additional-components":
      return <AdditionalComponents />;
    case "user-management":
      return <UserManagementSVG />;
    case "upload-audits":
      return <UploadAuditsSVG />;
    case "assign-reviews":
      return <AssignReviewsSVG />;
    case "reviews":
      return <ReviewsSVG />;
    case "live-reviews":
      return <LiveReviewsSVG />;
    case "moderated-reviews":
      return <ModeratedReviewsSVG />;
    case "dashboard":
      return <DashboardSVG />;
    case "export":
      return <ExportSVG />;
    case "query-builder":
      return <QueryBuilderSVG />;
    case "further-data":
      return <FurtherDataSVG />;
    case "theme-comments":
      return <ThemeCommentsSVG />;
    case "explanatory-comments":
      return <ValueAdded />;
    case "outcomes-monitor":
      return <OutcomesMonitorSVG />;
    default:
      return <Icon />;
  }
}
