import { useEffect, useState } from "react";
import { getPreviewContent, textConverter } from "./ReportBuilderUtils";

export default function Preview({ items }) {
  const [previewContent, setPreviewContent] = useState(
    getPreviewContent(items)
  );

  useEffect(() => {
    setPreviewContent(getPreviewContent(items));
  }, [items]);

  return (
    <div className="preview">
      {previewContent?.map((i, _key) => (
        <div key={_key}>{textConverter(i)}</div>
      ))}
    </div>
  );
}
export { Preview };
