import Select from "./SelectField";
import "./MultipleChoiceQuestion.scss";
import { useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import CommentBox from "./CommentBox";

export default function MultipleChoiceQuestion({
  name,
  options,
  value = {},
  onChange: _onChange,
  className = "",
  notGraded,
}) {
  const [comment, setComment] = useState(value.comment || "")
  const onChange = (value) => {
    _onChange({ ...value, notGraded });
  };

  const updateComment = useDebouncedCallback((comment) => onChange({ ...value, comment }), 300)
  const handleUpdateComment = (value) => {
    setComment(value)
    updateComment(value)
  }

  const currentValue = value.answerId
    ? options?.find((a) => a.id === value?.answerId)
    : options?.find((a) => a.options?.value === value?.value);

  const commentsShowing = currentValue?.allowcomment;

  return (
    <div className={"multiple-choice-question question " + className}>
      <div className="base">
        <h3>{name}</h3>
        <span>
          <Select
            options={options
              .map(o => o.options)
            }
            onChange={({ value: newValue }) => {
              if (value !== newValue) onChange({ ...value, value: newValue });
            }}
            value={currentValue?.options}
          />
        </span>
      </div>
      <div className={"expandable" + (commentsShowing ? " visible" : "")}>
        <CommentBox comment={comment} updateComment={handleUpdateComment} placeholder="Type your reasoning here" />
      </div>
    </div>
  );
}
