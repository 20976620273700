import React from "react";
import Select from "react-select";

export const reactSelectStyles = {
  placeholder: (provided) => ({
    ...provided,
    fontFamily: "Arboria",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "var(--font-size-small)",
    color: "var(--background-blue)",
  }),
  input: (provided) => ({
    ...provided,
    fontFamily: "Arboria",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "var(--font-size-small)",
    color: "var(--background-blue)",
  }),
  singleValue: (provided) => ({
    ...provided,
    fontFamily: "Arboria",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "var(--font-size-small)",
    color: "var(--background-blue)",
  }),
  control: (provided) => ({
    ...provided,
    borderRadius: "4px",
    background: "rgba(0, 51, 102, 0.1)",


  }),
};

export default function SelectField({ value = null, onChange, ...props }) {
  return (
    <Select
      isClearable={false}
      styles={reactSelectStyles}
      onChange={(newValue) => onChange(newValue || value)}
      value={value}
      {...props}
    />
  );
}
