import { useMemo } from "react";
import { ReactComponent as BinIcon } from "./assets/bin-2.svg";
import { ReactComponent as RightArrow } from "../../assets/rightarrow.svg";
import SelectField from "./SelectField";

import "./Filter.scss";
import Button from "./Button";

/**
 * Filter compoent.
 */
export const Filter = ({
  /* Options config. */
  config = {},

  /* Controlled value. */
  value = { type: undefined, value: [] },

  /**
   * Change handler.
   */
  onChange = (value) => {},

  /**
   * Remove handler.
   */
  onRemove = () => {},
}) => {
  const valueOptions = useMemo(
    () => config[value.type]?.list?.map(
      ({ label, value }, index) => ({ label, value })
  ), [config, value])
  const typeOptions = useMemo(
    () => Object.entries(config)
    .map(([type, { title }], index) => ({ label: title, value: type })),
  [config])
  const valueValue = useMemo(() => valueOptions?.find(({ value: v }) => v === value.value), [value, valueOptions])
  const typeValue = useMemo(() => typeOptions?.find(({ value: v }) => v === value.type), [value, typeOptions])
  
  /**
   * Paints the correct filter based on the type.
   */
  const PaintFilter = useMemo(() => {
    const filterConfig = config[value.type];

    switch (filterConfig?.style) {
      case "date":
        return (
          <p className="filter-date-inputs">
            <input
              value={value?.value?.[0]}
              onChange={(e) => {
                onChange({
                  ...value,
                  value: [e.target.value, value?.value?.[1]],
                });
              }}
              type="date"
            />
            <RightArrow />
            <input
              value={value?.value?.[1]}
              onChange={(e) => {
                onChange({
                  ...value,
                  value: [value?.value?.[0], e.target.value],
                });
              }}
              type="date"
            />
          </p>
        );
      case "list":
        return (
          <div className="select-wrapper">
            <SelectField
              title="Select filter values"
              placeholder="Please select a filter value."
              options={valueOptions}
              value={valueValue}
              onChange={({ value: v }) => {
                onChange({ ...value, value: v });
              }}
            />
          </div>
        );
      default:
        return <></>;
    }
  }, [onChange, config, value]);

  return (
    <div className="filter">
      <div className="inputs">
        <div className="filter-type">
          <div className="select-wrapper">
            <SelectField
              title="Select filter types"
              placeholder="Please select a filter type."
              options={typeOptions}
              value={typeValue}
              onChange={({ value }) => {
                onChange({ type: value })
              }}
            />
          </div>
          <Button
            onClick={onRemove}
            type="primary"
          >
            <BinIcon />
          </Button>
        </div>
        {config[value.type] && <div className="filter-value">{PaintFilter}</div>}
      </div>
    </div>
  );
};
