import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import { lazy } from "react";

import { TopNav } from "./components";
import { useUser } from "./api/auth";
import { useTracking } from "./util/useTracking";
import ModuleContext from "./context/ModuleContext";

const Error404 = lazy(() => import("./routes/Errors/404"));
const ErrorUnplanned = lazy(() => import("./routes/Errors/unplanned"));

const LoginPage = lazy(() => import("./routes/Auth/Login"));
const OneTimePasscodePage = lazy(() => import("./routes/Auth/OneTimePasscode"));

const ResetPage = lazy(() => import("./routes/Auth/Reset"));
const ForgotPage = lazy(() => import("./routes/Auth/Forgot"));

const Reports = lazy(() => import("./routes/Reports/index.js"));
const Coordinator = lazy(() => import("./routes/Coordinator/index.js"));
const Review = lazy(() => import("./routes/Reviews/index.js"));
const Feedback = lazy(() => import("./routes/Feedback/index.js"));

/**
 * Adds the header component to routes.
 */
const WithHeader = ({ children }) => {
  const { user, logout } = useUser();
  const module = ModuleContext.useModule();
  const menuItems = [
    { text: "Audit", path: "/reviews/" },
    { text: "Reports", path: "/reports/" },
    { text: "Coordinator", path: "/coordinator/" },
  ];
  return (
    <>
      {
        <TopNav
          title={`${module.name} ${module.description}`}
          user={user}
          logout={logout}
          menuItems={menuItems}
        />
      }
      {children}
    </>
  );
};

/**
 * Protects routes that require authentication.
 */
const ProtectedRoute = ({ children }) => {
  const { user, error, loading } = useUser();
  const location = useLocation();

  if (!user && !(!error && loading))
    return (
      <Navigate
        replace
        to={{
          pathname: "/login",
          state: { from: location },
        }}
      />
    );

  if (user && user.role) {
    const roleMap = {
      audit: ["/reviews"],
      report: ["/reports"],
      manage: ["/reviews", "/reports"],
      admin: ["/reviews", "/reports", "/coordinator"],
      super: ["/reviews", "/reports", "/coordinator"],
    };
    if (!roleMap[user.role].some((root) => location.pathname.startsWith(root)))
      return (
        <Navigate
          replace
          to={{
            pathname: roleMap[user.role][0],
          }}
        />
      );
  }

  return children;
};

export default function Router(props) {
  // GA tracking.
  useTracking();

  return (
    <>
      <Routes>
        <Route path="/" element={<Navigate to="/login" />} />
        <Route path="login" element={<LoginPage />} />
        <Route path="onetimepasscode" element={<OneTimePasscodePage />} />
        <Route path="forgot" element={<ForgotPage />} />
        <Route path="reset/:token" element={<ResetPage />} />
        <Route path="/feedback/*" element={<Feedback />} />
        <Route path="set/:token" element={<ResetPage setting={true} />} />
        <Route path="/error/down" element={<ErrorUnplanned />} />

        <Route
          path="/reports/*"
          element={
            <ProtectedRoute>
              <WithHeader>
                <Reports />
              </WithHeader>
            </ProtectedRoute>
          }
        />
        <Route
          path="/coordinator/*"
          element={
            <ProtectedRoute>
              <WithHeader>
                <Coordinator />
              </WithHeader>
            </ProtectedRoute>
          }
        />
        <Route
          path="/reviews/*"
          element={
            <ProtectedRoute>
              <WithHeader>
                <Review />
              </WithHeader>
            </ProtectedRoute>
          }
        />

        <Route path="*" element={<Error404 />} />
      </Routes>
    </>
  );
}
