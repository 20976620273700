import Button from "../../Button";
import "./ReportBlock.scss";

export default function ReportBlockBroken({
  config: { removeItem = () => {} },
}) {
  return (
    <div
      className="report-block broken-widget"
      onClick={(e) => {
        e.preventDefault();
        removeItem();
      }}
    >
      <div className="border">
        <p>Something went wrong!</p>
        <Button onClick={removeItem}>Remove</Button>
      </div>
    </div>
  );
}

export { ReportBlockBroken };
