import { useMemo, useState } from "react";
import { GridBlocksEnum } from "../ReportGridTypes";
import "./ReportBlock.scss";

// Icons.
import { ReactComponent as CustomChartsSVG } from "../../assets/block_chart.svg";
import { ReactComponent as CustomTextSVG } from "../../assets/block_text.svg";
import { ReactComponent as CommentsSVG } from "../../assets/block_comments.svg";

export default function ReportBlockAdd({ config: { addItem = (type) => {} } }) {
  // Current render state.
  const [state, setState] = useState("initial");

  // Blocks definitions.
  const blocks = useMemo(
    () => [
      {
        name: "Custom Chart",
        type: GridBlocksEnum.CUSTOM_CHART,
        icon: <CustomChartsSVG />,
      },
      {
        name: "Comments",
        type: GridBlocksEnum.COMMENTS,
        icon: <CommentsSVG />,
      },
      {
        name: "Custom Text",
        type: GridBlocksEnum.CUSTOM_TEXT,
        icon: <CustomTextSVG />,
      },
    ],
    []
  );

  // Render memo.
  const render = useMemo(() => {
    switch (state) {
      case "blocks":
        return (
          <div className="blocks">
            {blocks.map(({ name, icon, type }, _index) => (
              <div
                key={_index}
                className="block"
                onClick={() => {
                  setState("initial");
                  addItem({ type, visible: true });
                }}
              >
                {icon}
                <p>{name}</p>
              </div>
            ))}
          </div>
        );
      default:
        return (
          <button
            className="bordered"
            onClick={(e) => {
              e.preventDefault();
              setState("blocks");
            }}
          >
            + Add Block
          </button>
        );
    }
  }, [state, addItem, blocks]);

  return <div className="report-block add-widget">{render}</div>;
}

export { ReportBlockAdd };
