import "@inovua/reactdatagrid-community/index.css";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import "./Table.scss";

export default function Table({
  columns = [],
  data = [],
  dataSource,
  idProperty,
  ...props
}) {
  /**
   * Standardises the table input for our library.
   */
  const mappedColumns = columns.map(
    ({ label, dataKey, cellRenderer, width, flexGrow, ...rest }) => ({
      name: dataKey,
      header: label,
      defaultFlex: flexGrow,
      defaultWidth: width,
      render:
        typeof cellRenderer === "function"
          ? ({ data }) => cellRenderer({ dataKey, rowData: data })
          : undefined,
      ...rest,
    })
  );

  return (
    <div className="table-wrapper">
      <ReactDataGrid
        className="rdg-table"
        columns={mappedColumns}
        idProperty={idProperty || mappedColumns[0]?.name || undefined}
        pagination={data.length > 15 || !!dataSource}
        dataSource={dataSource ? dataSource : data}
        {...props}
      />
    </div>
  );
}
