import React, { useEffect, useState, useContext } from "react";
import { getToolData, getGrades } from "../api/audits";
import { useUser } from "../api/auth";
import textCopy from "./textCopy/textCopy.json";
import { createModuleFlaggedFeatures } from "../util/featureFlagging";
import config, { flaggedFeatures } from "../config";

/* 
@param {string} name
@returns {object<{audits: {dateOf:String}}>} 
@desc get text copy for current module
*/
const moduleCopy = (name) => textCopy[name];

const ModuleContext = React.createContext();

function useModule() {
  return useContext(ModuleContext);
}

function Provider({ children }) {
  const [module, setModule] = useState({});
  const { user } = useUser();
  const [isDataReady, setIsDataReady] = useState(false);

  const fetchData = async () => {
    try {
      const res = await getToolData(); // process.env.REACT_APP_MODULE_ID
      res.grades = await getGrades(process.env.REACT_APP_GRADES);
      setModule(res);
      setIsDataReady(true);
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    if (user) fetchData();

    return () => {};
  }, [user]);

  const contextValue = isDataReady
    ? {
        ...module,
        textCopy: moduleCopy(module.name),
        flaggedFeatures: createModuleFlaggedFeatures(
          flaggedFeatures,
          module.name
        ),
      }
    : {};

  return (
    <ModuleContext.Provider value={contextValue}>
      {children}
    </ModuleContext.Provider>
  );
}

const Module = {
  Provider,
  useModule,
};

export default Module;
