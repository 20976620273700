import { useState } from "react";
import "./DateRangePicker.scss";
import DateRangePickerNPM from "@wojtekmaj/react-daterange-picker";
/**
 * A customizable date range picker component.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Array} [props.initialValue=[null, null]] - The initial date range value.This defaults null if we want an initial date we can pass in a date object on this prop 
 * @param {Function} [props.onChange] - A callback function triggered when the date range changes.
 * @returns {JSX.Element} The rendered date range picker component.
 *  @example
 * // Example usage of DateRangePicker component
 * <DateRangePicker
 *   initialValue={['2023-08-01', '2023-08-15']}
 *
 * />
 */
 
export default function DateRangePicker({ initialValue = [null, null], onChange = () => {} }) {
  const [value, setValue] = useState(initialValue);
  function onChangeInternal(value) {
    setValue(value);
    onChange(value);
  }

  return (
    <>
      <DateRangePickerNPM
        onChange={onChangeInternal}
        value={value}
        minDetail="decade"
        showLeadingZeros
        format="dd-MM-yyyy"
      />
    </>
  );
}
