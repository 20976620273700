import "./prompt.scss";

export default function Prompt({ children }) {
  return (
    <div className="prompt">
      <h1><img alt="The Invision360 logo" src={process.env.PUBLIC_URL + "/logo.png"} /></h1>
      <div className="prompt-content">{children}</div>
    </div>
  );
}

export { Prompt };
