/**
 * Filters the given navigation items based on the user/modules flagged features.
 * NB Even though we pass the user object, feature flagging is done per module
 * All the users on one module will have the same flagged features
 *
 * @param {Array} navItems - The array of navigation items to filter.
 * @param {Object} moduleFlaggedFeatures - The user object containing flagged features.
 * @returns {Array} - The filtered array of navigation items.
 */
const filterFlaggedFeatures = (navItems, moduleFlaggedFeatures) =>
  navItems.filter(({ label }) => {
    // remove space from label
    const compareLabel = label.replace(/\s/g, "").toLowerCase();

    // If the label matches a key in moduleFlaggedFeatures and the value is true, keep the item
    // If the label matches a key in moduleFlaggedFeatures and the value is false, remove the item
    // If the label does not match any key in moduleFlaggedFeatures, keep the item

    const check = !Object.keys(moduleFlaggedFeatures).some((key) => {
      const compareKey = key.replace(/\s/g, "").toLowerCase();
      return compareKey === compareLabel && !moduleFlaggedFeatures[key];
    });
    return check;
  });

/**
 * Creates a module-specific object of flagged features based on the provided flagged features object and module name.
 *
 * @param {Object} flaggedFeatures - The object containing all flagged features and the modules they are allowed on.
 * @param {string} moduleName - The name of the module.
 * @returns {Object} - The module-specific object of flagged features.
 */
const createModuleFlaggedFeatures = (flaggedFeatures, moduleName) => {
  const moduleFlaggedFeatures = Object.entries(flaggedFeatures).reduce(
    (acc, [key, value]) => {
      return {
        ...acc,
        [key]: flaggedFeatures[key].includes(moduleName),
      };
    },
    {}
  );
  return moduleFlaggedFeatures;
};
export { filterFlaggedFeatures, createModuleFlaggedFeatures };
