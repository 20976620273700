import React, { useEffect, useState } from "react";
import "./AssignPlan.scss";
import { Button } from "./Button.jsx";
import Table from "./Table";
import { Modal } from "./Modal.jsx";
import { FormToggle } from "./FormToggle";

import { ReactComponent as UsersSVG } from "../stories/assets/users.svg";
import { useMemo } from "react";

const stages = {
  ASSIGN: "assign",
  DEADLINES: "deadlines",
};

export default function AssignPlan({
  controls,
  list = [],
  disabledRows = {},
  details = {},
  onSearch = (value) => {},
  onAssign = (selectedPlans) => {},
}) {
  /**
   * State storing which stage of the assign wizard we're on.
   *
   * 'assign' is step one.
   * 'deadlines' is step two.
   */
  const [stage, setStage] = useState(stages.ASSIGN);
  const [selectedPlans, setSelectedPlans] = useState();

  // Set stage to start back when modal closes.
  useEffect(() => {
    if (!controls.isOpen) {
      setStage(stages.ASSIGN);
    }
  }, [controls.isOpen]);

  /**
   * Buttons to show on the modal, update dynamically via stage.
   */
  const buttons = useMemo(
    () => (
      <>
        {stage === stages.ASSIGN && (
          <Button
            type="primary"
            disabled={!Object.keys(selectedPlans || {}).length}
            onClick={() => setStage(stages.DEADLINES)}
          >
            Next
          </Button>
        )}
        {stage === stages.DEADLINES && (
          <>
            <Button type="primary" onClick={() => setStage(stages.ASSIGN)}>
              Back
            </Button>
            <Button
              type="primary"
              onClick={() =>
                onAssign(
                  Object.values(selectedPlans).map(({ id, deadlineDate }) => ({
                    id,
                    deadlineDate,
                  }))
                )
              }
            >
              Assign
            </Button>
          </>
        )}
      </>
    ),
    [stage, setStage, selectedPlans, onAssign]
  );

  /**
   * Shows a selected plan and allows deadline date input.
   */
  const SelectedPlanTile = ({ onChange = () => {}, pepId }) => {
    // Has deadline state.
    const [hasDeadline, setHasDeadline] = useState(false);

    // Emit undefined to clear date when switched to no.
    useEffect(() => {
      if (!hasDeadline) onChange(undefined);
    }, [hasDeadline, onChange]);

    return (
      <div className="deadline-title">
        <div className="title-half left">
          <p>
            Plan <b>{pepId}</b>
          </p>
          <FormToggle
            options={[
              { label: "Yes", value: true },
              { label: "No", value: false },
            ]}
            default={false}
            onChange={(val) => {
              setHasDeadline(val);
            }}
          ></FormToggle>
        </div>
        <div className="title-half centre">
          {hasDeadline ? (
            <>
              <input
                type="date"
                placeholder="Deadline Date"
                onChange={(e) => {
                  onChange(e.target.value);
                }}
              ></input>
            </>
          ) : (
            "No Deadline"
          )}
        </div>
      </div>
    );
  };

  return (
    <Modal
      buttons={buttons}
      title="Assign Plans"
      controls={controls}
      closeText="Cancel"
    >
      <div className="assign-plans">
        <div className="auditor">
          <span>NAME:</span>
          <input className="audit-field" readOnly value={details?.name} />
          <span>EMAIL:</span>
          <input className="audit-field" readOnly value={details?.email} />
        </div>
        {stage === stages.ASSIGN ? (
          <>
            <div className="search-wrapper">
              <input
                placeholder="Search"
                onChange={({ target: { value } }) => onSearch(value)}
              />
            </div>
            <Table
              columns={[
                { label: "Internal ID", dataKey: "pepId", width: 100 },
                {
                  label: "Current Care",
                  dataKey: "currentSocialCare",
                  width: 200,
                },
                { label: "Key Stage", dataKey: "keyStage", width: 200 },
                {
                  label: "Venue",
                  dataKey: "settingName",
                  minWidth: 150,
                  flexGrow: 4,
                },
                {
                  label: "Uploaded",
                  dataKey: "createdAt",
                  width: 100,
                  cellDataGetter: ({ dataKey, rowData }) => {
                    if (typeof rowData.get === "function") {
                      return rowData.get(dataKey);
                    } else {
                      return rowData[dataKey];
                    }
                  },
                },
                { label: "Team Region", dataKey: "teamRegion", width: 120 },
                {
                  label: "Assigned to",
                  dataKey: "pendingReviewers",
                  minWidth: 80,
                  flexGrow: 2,
                  render: ({ data: { pendingReviewers } }) => {
                    return (
                      <div className="assignees">
                        <UsersSVG />
                        <span>
                          {pendingReviewers >= 1 ? pendingReviewers : ""}
                        </span>
                      </div>
                    );
                  },
                },
              ]}
              dataSource={list}
              disabledRows={disabledRows}
              checkboxColumn={true}
              multiSelect={true}
              onSelectionChange={(val) => {
                setSelectedPlans(val?.selected);
              }}
              idProperty="id"
              emptyText={
                <section>
                  <h2 className="text-center">No plans found.</h2>
                  <br /> To upload plans, go to{" "}
                  <a href="/coordinator/upload-audits">
                    Cooridinator &gt; Upload Plans
                  </a>
                  .
                </section>
              }
            />
          </>
        ) : (
          <div className="assign-deadlines">
            <p>Would you like to set any deadlines?</p>
            <div className="deadline-list">
              {Object.entries(selectedPlans || {}).map(
                ([key, { pepId, id: planId }]) => (
                  <SelectedPlanTile
                    key={key}
                    pepId={pepId}
                    onChange={(date) => {
                      // Update selected plans with deadline.
                      const newPlans = selectedPlans;
                      newPlans[key].deadlineDate = date;
                      setSelectedPlans(newPlans);
                    }}
                  ></SelectedPlanTile>
                )
              )}
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
}
