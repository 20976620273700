import "./ReportEditModal.scss";

import { Modal } from "../Modal";
import Button from "../Button";

import { GridBlocksEnum } from "./ReportGridTypes";
import { ModalFormCharts, ModalFormComments } from "./GridForms/index.js";
import {
  ReportBlockComments,
  ReportBlockBarChart,
} from "./GridBlocks/index.js";
import { useEffect, useMemo, useState } from "react";

import { defaultConfig, noRender } from "./ReportGridTypes";

// Forms by block type.
const modalFormComponents = {
  [GridBlocksEnum.CUSTOM_CHART]: ModalFormCharts,
  [GridBlocksEnum.COMMENTS]: ModalFormComments,
};

const modalPreviewComponents = {
  [GridBlocksEnum.COMMENTS]: ReportBlockComments,
  [GridBlocksEnum.CUSTOM_CHART]: ReportBlockBarChart,
};

/**
 * Shows an edit form by block type and handles the update
 * @param {*} param0
 * @returns
 */
export default function ReportEditModal({
  controls,
  block,
  getData = () => {},
  updateBlock = (block) => {},
  onSubmit = (block) => {},
  onAfterClose = () => {},
  updateModalBlock,
  formatToDate = (date) => {},
  items,
  context,
}) {
  // Dynamically resolve form based on type.
  const FormByType = useMemo(() => {
    return modalFormComponents[block.type];
  }, [block]);

  // Dynamically resolve preview/block based on type.
  const PreviewType = useMemo(() => {
    return modalPreviewComponents[block.type];
  }, [block]);

  // Config form state.
  const [config, setConfig] = useState(
    block.config || defaultConfig(block.type, context)
  );

  // Dep data for config form, call once on start.
  const [formData, setFormData] = useState(null);

  // This will get updated with the config data from the UI
  const [content, setContent] = useState({});

  // Fetch form data.
  useEffect(() => {
    let active = true;
    const data = async () => {
      const formType = `SETTING_${block.type}`; //?
      const formDataSettings = await getData(formType);

      if (active) {
        setFormData(formDataSettings);
      }
    };
    data();
    return () => {
      active = false;
    };
  }, [block.type, getData]);

  // set static config
  useEffect(() => {
    let active = true;
    const data = async () => {
      // Refresh the block data.
      const data = await getData(block.type, config);
      if (active) {
        setContent({ ...data });
      }
    };
    data();
    return () => {
      active = false;
    };
  }, [config, block, getData]);

  const noContent = noRender(block.type, config);

  return (
    <>
      {!content || !formData ? (
        <div></div>
      ) : (
        <Modal
          title={`${block?.created ? "Edit" : "Add"} a block.`}
          controls={controls}
          plain={true}
          showControls={noContent}
          size="rb"
          buttons={
            <Button
              disabled={!noContent}
              onClick={() => {
                onSubmit({
                  ...block,
                  content,
                  config,
                });
                controls.close();
              }}
            >
              {"Save"}
            </Button>
          }
          afterClose={onAfterClose}
        >
          <section className="report-edit-modal-flex side-by-side">
            <FormByType
              formData={formData}
              block={content}
              setBlock={setContent}
              config={config}
              setConfig={setConfig}
              formatToDate={formatToDate}
              module={context?.name}
              context={context}
            />
            <PreviewType config={config} content={content} />
          </section>
        </Modal>
      )}
    </>
  );
}

export { ReportEditModal };
