import React from "react";
import "./ModerateAudits.scss";
import { Modal } from "./Modal.jsx";

export default function ModerateAudits({
  controls,
  pepId,
  audits = [],
  themes,
  viewAudit = (audit, section = 1) => {},
  nominated = {},
  setNominated = () => {},
  criteria,
}) {
  /**
   * Sections to be listed.
   */
  const sections = themes;

  const capitalise = (s) => {
    if (s) {
      return `${s[0].toUpperCase()}${s.slice(1)}`;
    }
  };

  /**
   * Indicators for section differences.
   */
  const sectionHasDiscrepencies = sections
    .map(({ id }) => {
      // Calc sum of each grade.
      const outcomes = audits
        // Fix undefineds.
        .map(({ sections: s }) =>
          id && s[id]?.grade ? s[id].grade : "No grade"
        )
        // Count.
        .reduce((prev, curr) => {
          prev[curr] = prev[curr] ? prev[curr] + 1 : 1;
          return prev;
        }, {});

      // Count max similarities.
      const maxSimilarities = Math.max(
        ...Object.entries(outcomes).map(([_, count]) => count)
      );

      return {
        id,
        differences: Object.keys(outcomes).length !== 1,
        similarities: `${maxSimilarities}/${audits.length}`,
      };
    })
    // Convert to object keyed by ID.
    .reduce((prev, curr) => {
      prev[curr.id] = { ...curr };
      return prev;
    }, {});

  // Boolean for overall differences.
  const overallDifferences =
    Object.entries(
      audits
        .map(({ outcome }) => (outcome ? outcome : "No grade"))
        .reduce((prev, curr) => {
          prev[curr] = prev[curr] ? prev[curr] + 1 : 1;
          return prev;
        }, {})
    ).length > 1;

  return (
    <Modal title={`Moderated Review - ${pepId}`} controls={controls}>
      <p className="moderated-audits-blurb">
        Below is a breakdown of audits for this plan {pepId}. Clicking on a{" "}
        <button className={`chip active`}>Grade</button>will show you a summary
        of that audit.
      </p>
      <p>
        Click the auditor name of an audit to nominate it for this plan. A
        nominated audit represents a plan in reporting.
      </p>
      <div className="moderated-audits">
        <table>
          {/* Header row */}
          <thead>
            <tr>
              <td className="tr-header">Auditor</td>
              <td className="compare"></td>
              {audits.map((audit) => {
                const { auditor } = audit;
                const isNominated =
                  audit?.assignmentId === nominated?.assignmentId;

                return (
                  <td
                    key={audit.id}
                    className={`head-cell ${isNominated ? "nominated" : ""}`}
                  >
                    <label>
                      {isNominated ? (
                        <button className={`chip chip_nominated`}>
                          Nominated
                        </button>
                      ) : (
                        ""
                      )}
                      <br />
                      <input
                        type="radio"
                        checked={isNominated}
                        onChange={() => setNominated(audit?.assignmentId)}
                      />
                      <b>{auditor}</b>
                    </label>
                  </td>
                );
              })}
            </tr>
          </thead>

          <tbody>
            {/* Overall outcome row. */}
            <tr>
              <td className="tr-header">Overall Grade</td>
              <td className="compare">
                <div
                  className={`indicator ${
                    overallDifferences ? "red" : "green"
                  }`}
                  title={
                    overallDifferences
                      ? "Discrepency in grading."
                      : "No discrepency in grading."
                  }
                ></div>
              </td>
              {audits.map((audit) => {
                const { outcome, color } = audit;
                const isNominated =
                  audit?.assignmentId === nominated?.assignmentId;
                return (
                  <td key={audit.id} className={isNominated ? "nominated" : ""}>
                    <button
                      style={{ background: color }}
                      className={`chip chip_${capitalise(outcome)} active`}
                      onClick={(e) => viewAudit(audit)}
                    >
                      {capitalise(outcome)}
                    </button>
                  </td>
                );
              })}
            </tr>

            {/* Loop section rows. */}
            {sections.map(
              ({ name, id: sectionId, description, themeId }, _index) => {
                const isOptional = !!criteria?.find(({ id }) => id === themeId)
                  .optional;
                return (
                  <tr key={_index}>
                    {/* Left header col */}
                    <td className="tr-header">
                      {name}
                      {description && <small>{description}</small>}
                    </td>

                    {/* Right comparison col */}
                    <td className="compare">
                      {sectionHasDiscrepencies[sectionId].similarities}
                    </td>

                    {/* Audit section cols */}
                    {audits.map((audit) => {
                      const { outcome, sections: auditSections } = audit;
                      const isNominated =
                        audit?.assignmentId === nominated?.assignmentId;

                      const reducedSections = {
                        ...Object.entries(auditSections || {})
                          .map(([key, value]) => ({
                            id: key,
                            outcome: value.grade,
                            color: value.color,
                          }))
                          .reduce((prev, curr) => {
                            prev[curr.id] = {
                              outcome: capitalise(curr.outcome),
                              color: curr.color,
                            };
                            return prev;
                          }, {}),
                        summary: { outcome: capitalise(outcome) },
                      };

                      return (
                        <td
                          key={audit.id}
                          className={isNominated ? "nominated" : ""}
                        >
                          {(reducedSections[sectionId] && (
                            <button
                              style={{
                                background: reducedSections[sectionId].color,
                              }}
                              onClick={() => viewAudit(audit, themeId)}
                              className={`chip chip_${reducedSections[sectionId].outcome} active`}
                            >
                              {isOptional
                                ? "Optional"
                                : reducedSections[sectionId]?.outcome
                                ? reducedSections[sectionId]?.outcome
                                : "Ungraded"}
                            </button>
                          )) || (
                            <button
                              onClick={(e) => e.preventDefault()}
                              className={`chip active`}
                            >
                              Ungraded
                            </button>
                          )}
                        </td>
                      );
                    })}
                  </tr>
                );
              }
            )}
          </tbody>
        </table>
      </div>
    </Modal>
  );
}
