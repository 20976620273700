import { useState } from "react";
import ReactModal from "react-modal";
import Button from "./Button.jsx";
import "./modal.scss";

/**
 * Controls hook for modal.
 */
export const useModalControls = (ariaHideApp = false) => {
  const [state, setState] = useState(false);

  const open = () => {
    setState(true);
  };
  const close = () => {
    setState(false);
  };

  return {
    /* Modal open state. */
    isOpen: state,
    /* Opens modal. */
    open,
    /* Closes modal. */
    close,
    /* Optout Aria Screen readers */
    ariaHideApp,
  };
};

export const Modal = ({
  /* A controls object from modal controls hook - default to bare minimum. */
  controls = {
    isOpen: false,
    open: () => {},
    close: () => {},
  },

  /* Modal content. */
  children = <></>,

  /* Extra buttons. */
  buttons = <></>,

  /* Modal title. */
  title = "",

  /* Size, "wide", "mini" or "narrow". */
  size = "wide",

  /* Alternative text on the close button. */
  closeText = "Close",

  /**
   * Removes header, and allows content to take full modal.
   */
  plain = false,

  /**
   * Removes header, and allows content to take full modal.
   */
  noPadding = false,

  /* Handler called after closing. */
  afterClose = () => {},
}) => {
  /**
   * Predetermined sizes.
   */
  const sizes = {
    wide: {
      width: "90%",
      maxWidth: "90%",
      minWidth: "480px",
    },
    mini: {
      width: "90%",
      maxWidth: "700px", // Is set in ./modal.scss due to library limitations.
      minWidth: "400px",
      height: "400px",
    },
    narrow: {
      width: "60%",
      maxWidth: "45vw",
      minWidth: "480px",
    },
    experiential: {
      width: "450px",
      maxWidth: "500px",
      minWidth: "405px",
      minHeight: "600px",
      height: "600px",
    },
    grading: {
      width: "fit-content",
      maxWidth: "90%",
      minWidth: "480px",
      height: "fit-content",
    },
    fitContent: {
      minWidth: "480px",
      maxWidth: "900px",
      height: "fit-content",
    },
  };

  const style = {
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.75)",
    },
    content: {
      ...sizes[size],
      padding: (plain || noPadding )? "0px" : "37px 2rem",
      borderRadius: "20px",
    },
  };

  return (
    <div>
      <ReactModal
        isOpen={controls.isOpen}
        onRequestClose={controls.close}
        style={style}
        ariaHideApp={controls.ariaHideApp}
      >
        {(!plain && (
          <div className="modal-header">
            {typeof title !== "string" ? title : <h3>{title}</h3>}
          </div>
        )) ||
          ""}

        <div className={`modal-content size-${size} ${plain ? "plain" : ""}`}>
          {children}
        </div>

        <div className={`modal-footer ${plain ? "plain" : ""}`}>
          <div className="modal-buttons">
            {buttons}
            <Button
              type="secondary"
              onClick={() => {
                controls.close();
                if (afterClose) afterClose();
              }}
            >
              {closeText}
            </Button>
          </div>
        </div>
      </ReactModal>
    </div>
  );
};
