import React from "react";
import "./Rating.scss";

export default function Rating({
  active,
  grading,
  hideSilver,
  perComplete,
  module,
  showProgressBar
}) {
  return (
    <div className="rating-wrapper">
      <div className="rating">
        <span className="title_Key">Rating :</span>
        {grading.map(
          (g) =>
            ((g.name === "Silver" && !hideSilver) || g.name !== "Silver") && (
              <button
                key={g.id}
                style={{ backgroundColor: g.colour }}
                className={active === g.id ? "active" : ""}
              >
                {g.name}
              </button>
            )
        )}
      </div>
      { showProgressBar && <div className="progress">
        <div
          className="progress-1"
          style={{ width: (perComplete * 100).toFixed(2) + "%" }}
        >
          {
            perComplete >= 0.1 && (
              <span>{(perComplete * 100).toFixed()}%</span>
            ) /* Percentage in bar when over 10%, so text can fit. */
          }
        </div>
        {
          perComplete < 0.1 && (
            <span>{(perComplete * 100).toFixed()}%</span>
          ) /* Next to bar below 10%. */
        }
      </div>
    }
    </div>
  );
}
