import Button from "./Button.jsx";
import "./ButtonBar.scss";
import FormInput from "./FormInput.jsx";
import DateRangePicker from "./DateRangePicker";
import Select from "react-select";
import { reactSelectStyles } from "./SelectField";
import DatePicker from "react-date-picker";

import "@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css";
import "react-calendar/dist/Calendar.css";

/**
 * Button bar component, handles showing a search field and CTAs.
 *
 * @param {(value) => void} onSearch - A callback, if provided with show a search field.
 * @param {Array<{label: string, onClick: () => void, type: Button.type}>} buttons - An array of buttons to show.
 */
export default function ButtonBar({ onSearch, filters = [], buttons = [] }) {
  return (
    <div className="button-bar">
      {!!onSearch && (
        <FormInput
          label="Search"
          name="button-bar-search"
          onChange={onSearch}
          inline
        />
      )}
      {filters?.map(
        (
          { label, placeholder, onChange, value, list, type = "text" },
          _index
        ) => {
          switch (true) {
            // List filter.
            case !!list:
              return (
                <div key={_index} className="select">
                  <Select
                    title={label}
                    placeholder={placeholder}
                    options={list}
                    value={value}
                    onChange={(value) => onChange(value || {})}
                    isClearable={true}
                  />
                </div>
              );

            // Date range filter.
            case type === "daterange":
              return (
                <label key={_index} className="atop">
                  <span>{label}</span>
                  <DateRangePicker
                    onChange={(values) => {
                      if (!values) return onChange(undefined);
                      onChange(values.map((d) => d.toISOString().slice(0, 10)));
                    }}
                  ></DateRangePicker>
                </label>
              );

            // Date filter
            case type === "date":
              return (
                <label key={_index} className="atop">
                  <span>{label}</span>
                  <DatePicker
                    onChange={(values) => {
                      onChange(values);
                    }}
                  ></DatePicker>
                </label>
              );

            // Standard text search field.
            default:
              return (
                <FormInput
                  key={_index}
                  label={label}
                  styles={reactSelectStyles}
                  placeholder={placeholder}
                  name="button-bar-search"
                  onChange={onChange}
                  atop
                  inline
                />
              );
          }
        }
      )}
      {buttons?.map(({ label, onClick, type }, _index) => (
        <Button key={_index} onClick={onClick} type={type || "primary"}>
          {label}
        </Button>
      ))}
    </div>
  );
}
