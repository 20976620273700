import "./ReportBlock.scss";
import { format } from "date-fns";

function ReportBlockComments({ content, config }) {
  if (content) {
    return (
      <div className="comments-block ">
        <h2> {config.title}</h2>
        <p className="comments-block-date-text">
          Comments for audits completed between the{" "}
          {config.from && format(new Date(config.from), "do 'of' MMMM yyyy")} and the{" "}
          {config.to && format(new Date(config.to), "do 'of' MMMM yyyy")}
        </p>
        {config.showThemeComments
        && <>
        <h2>Theme Comments</h2>
          {content?.themeComments?.length > 0 ?
            <ul>
             {content.themeComments?.map((el, index) => (
                <li key={index} className="theme-comments-list">
                  {el}
                </li>
              ))}
            </ul>
            : <p>There are no theme comments</p>}
        </>}
        {/* {
          content.showExplanatoryComments
          && <>
            <h2>Explanatory Comments </h2>
            {content.explanatoryComments.length > 0 ?
            <ul>
              {content.explanatoryComments.map(
                ([key, value, show], index) => {
                  if (show) {
                    return (
                      <section key={index}>
                        <h3>{key}</h3>
                        <ul className="explanatory-comments">
                          {value.map((el, index) => (
                            <li
                              key={index}
                              className="explanatory-comments-list"
                            >
                              {el}
                            </li>
                          ))}
                        </ul>
                      </section>
                    );
                  } else {
                    return "";
                  }
                }
              )}
            </ul>
            :<p>No question comments</p>}
          </>
        }
        <ul>
          {content.explanatoryComments.length > 0
            ? content.explanatoryComments.map(
                ([key, value, show], index) => {
                  if (show) {
                    return (
                      <section key={index}>
                        <h3>{key}</h3>
                        <ul className="explanatory-comments">
                          {value.map((el, index) => (
                            <li
                              key={index}
                              className="explanatory-comments-list"
                            >
                              {el}
                            </li>
                          ))}
                        </ul>
                      </section>
                    );
                  } else {
                    return "";
                  }
                }
              )
            : ""}
        </ul> */}
      </div>
    );
  } else {
    return (
      <section className="comments-block flex ">
        <section className="centre">
          <h2>Preview</h2>
          <p>Once you select options from the left, a preview will show here</p>
        </section>
      </section>
    );
  }
}

export { ReportBlockComments };
