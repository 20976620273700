import "./CommentBox.scss"

export default function CommentBox({ comment, placeholder, updateComment }) {
    const validateComment = (value, limit=2000) => {
        let text = value
        if(value?.length > limit) {
            text = text.slice(0, limit)
        }
        return text
    }

    return (
        <div className="comment-box">
            <textarea
                rows="3"
                placeholder={placeholder}
                onChange={({ target: { value } }) => {
                    const val = validateComment(value)
                    if(val === comment) return

                    updateComment(val)
                }
                }
                value={comment}
            />
            <div className="character-count">
                <p>{comment.length}/2000</p>
            </div>
        </div>)
}