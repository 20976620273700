import { Suspense as RSuspense } from "react";
import ErrorBoundary from "../ErrorBoundary";
import { Loader } from "../components";

export default function Suspense(props) {
  return (
    <ErrorBoundary>
      <RSuspense fallback={<Loader loading />} {...props} />
    </ErrorBoundary>
  );
}

export function withSuspense(Component) {
  return (props) => (
    <ErrorBoundary>
      <Suspense>
        <Component {...props} />
      </Suspense>
    </ErrorBoundary>
  );
}
