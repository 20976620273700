/* React */
import React, { useCallback, useEffect, useState } from "react";

/* Dnd */
import { DndContext, DragOverlay, closestCenter } from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
  SortableContext,
  arrayMove,
  verticalListSortingStrategy,
  useSortable,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

/* Item containers. */
import { ReportBlockAdd } from "./GridBlocks/ReportBlockAdd.jsx";

/* Icons. */
import { ReactComponent as SettingsIcon } from "../assets/settings.svg";
import { ReactComponent as DeleteIcon } from "../assets/bin-2.svg";
import { ReactComponent as ThreeDots } from "../assets/three-dots.svg";
import { ReactComponent as DuplicateIcon } from "../assets/duplicate.svg";
import { ReactComponent as VisibleIcon } from "../assets/visibility_on.svg";
import { ReactComponent as VisibleOffIcon } from "../assets/visibility_off.svg";
import { ReactComponent as Accordion } from "../assets/accordion.svg";

/* Style */
import "../../utils/font-family.scss";
import "./ReportGrid.scss";

/* Grid Blocks. */
import { ReportBlockCustomText } from "./GridBlocks/ReportBlockCustomText.jsx";
import { ReportBlockPieChart } from "./GridBlocks/ReportBlockPieChart.jsx";
import { ReportBlockBarChart } from "./GridBlocks/ReportBlockBarChart.jsx";
import ReportBlockBroken from "./GridBlocks/ReportBlockBroken.jsx";
import { ReportBlockComments } from "./GridBlocks/ReportBlockComments.jsx";
import { useModalControls } from "../Modal.jsx";
import ReportEditModal from "./ReportEditModal.jsx";
import {
  ConfiglessBlocks,
  GridBlocksEnum,
  GridBlockTitles,
} from "./ReportGridTypes.js";
import Tooltip from "./Tooltip.jsx";

/**
 * A hook for handling the report grid list.
 * @returns
 */
export const useReportGridList = () => {
  // Indexing key.
  const [index, setIndex] = useState(0);

  /**
   * Block list.
   *
   * example block
   * {
   *  id: '123',
   *  title: 'Title', // This is shown on the title bar of a block.
   *  config: {}, // This is how the block is configured
   * }
   */
  const [items, setItems] = useState([]);

  const addIdToBlock = (item) => {
    // Update indexing key.
    let newkey = index + 1;
    // Set index and items.
    setIndex(newkey);
    item.id = newkey;

    return item;
  };

  /**
   * Adds a block.
   */
  const addItem = useCallback(
    (item) => {
      /**
       * Here we use created to mark when an item has been added to the list and any further changes to the block will be an update operation
       * we can no longer use Id as it is added before submission
       */

      const newBlock = {
        ...item,
        created: true,
      };
      setItems(items.concat(newBlock));
      // return current block
      return newBlock;
    },
    [items]
  );

  /**
   * Removes a block.
   */
  const removeItem = useCallback(
    (itemId) => {
      setItems([...items.filter(({ id }) => id !== itemId)]);
    },
    [items]
  );

  /**
   * Updates an item.
   */
  const updateBlock = useCallback(
    (item) => {
      setItems([
        ...items.map((mItem) => {
          // Replace our requested ID.
          if (mItem.id !== item.id) {
            return mItem;
          } else {
            return item;
          }
        }),
      ]);
    },
    [items]
  );

  /**
   * Updates the current order to be used with muuri's order handler.
   * @param {Array} ids
   */
  const updateOrder = (ids) => {
    // Set items order by key.
    setItems(items.sort((a, z) => ids.indexOf(a.id) - ids.indexOf(z.id)));
  };

  return {
    items,
    setItems,
    addItem,
    removeItem,
    updateOrder,
    updateBlock,
    addIdToBlock,
  };
};

// Grid item component.
const Item = ({
  id,
  type,
  config = {},
  removeItem = () => {},
  handleEditItem = () => {},
  updateBlock = () => {},
  item = {},
  defaultViewing,
  dragging,
}) => {
  const [viewContent, setViewContent] = useState(defaultViewing);
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });
  const [name, setName] = useState(
    item?.config?.title ? ` - ${item?.config?.title}` : ""
  );
  const [showMoreOptions, setShowMoreOptions] = useState(false);

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  /* Map block types to block components. */
  const GridBlocksMap = {
    [GridBlocksEnum.CUSTOM_TEXT]: ReportBlockCustomText,
    [GridBlocksEnum.COMMENTS]: ReportBlockComments,
    [GridBlocksEnum.CUSTOM_CHART]:
      config?.type === "pie" ? ReportBlockPieChart : ReportBlockBarChart,
    [GridBlocksEnum.BLOCK_ADD]: ReportBlockAdd,
  };

  // Map our titles.
  const title = GridBlockTitles ? GridBlockTitles[type] : false;

  /**
   * Map the type to our component.
   */
  const BlockComponent =
    type && GridBlocksMap?.[type] ? GridBlocksMap[type] : ReportBlockBroken;

  useEffect(() => {
    setName(item?.config?.title ? ` - ${item?.config?.title}` : "");
  }, [item, item.config]);

  return (
    <div className="item" ref={setNodeRef} style={style}>
      <div className="item-content" style={{ opacity: dragging ? 0.2 : 1 }}>
        {(title && (
          <div className="title">
            <button
              className={viewContent ? "accordion" : "accordion open"}
              onClick={() => setViewContent((s) => !s)}
            >
              <Accordion />
            </button>
            <div className="text move-cursor" {...attributes} {...listeners}>
              <h3>
                {title}
                {name}
              </h3>
            </div>
            <div className="title-actions">
              <button
                onClick={() =>
                  updateBlock({
                    ...item,
                    visible: !item.visible,
                  })
                }
              >
                {item.visible ? <VisibleIcon /> : <VisibleOffIcon />}
              </button>
              <button
                className="more"
                onClick={() => setShowMoreOptions((s) => !s)}
              >
                <ThreeDots />
                {showMoreOptions && (
                  <Tooltip setVisible={setShowMoreOptions}>
                    <div className="more-options">
                      {/* <button onClick={()=>updateBlock({
                      ...item,
                      visible: !item.visible
                    })}>
                      {item.visible ? <VisibleOffIcon/> : <VisibleIcon/>}
                      <p>{item.visible ? 'Hide' : 'Show'}</p>
                    </button> */}
                      <button
                        onClick={() =>
                          handleEditItem({
                            type,
                            config: item.config,
                            created: false,
                            visible: true,
                          })
                        }
                      >
                        <DuplicateIcon />
                        <p>Duplicate</p>
                      </button>
                      {!ConfiglessBlocks.includes(type) ? (
                        <button onClick={() => handleEditItem(item)}>
                          <SettingsIcon />
                          <p>Settings</p>
                        </button>
                      ) : (
                        ""
                      )}
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          removeItem(id);
                        }}
                      >
                        <DeleteIcon />
                        <p>Delete</p>
                      </button>
                    </div>
                  </Tooltip>
                )}
              </button>
            </div>
          </div>
        )) ||
          ""}
        {viewContent && (
          <div className="item-block">
            <BlockComponent
              updateBlock={updateBlock}
              content={item.content}
              config={item.config}
              item={item}
              removeItem={() => removeItem(id)}
            />
          </div>
        )}
      </div>
    </div>
  );
};

// ReportGrid.
export const ReportGrid = ({
  // Hook parts.
  items = [],
  addItem = (block) => {},
  updateBlock = (block) => {},
  removeItem = () => {},
  setItems = () => {},
  addIdToBlock = (block) => {},
  fetchBlockData = (type, config) => {},
  formatToDate = (date) => {},
  context,

  // Sets grid to be one column.
  singleColumn,

  // Fetches form static form data (select distincts for dropdowns) - requested once on open of modal.
  getModalFormData = (formtype) => {},
}) => {
  // Add/edit modal state.
  const modalControls = useModalControls();
  const [modalBlock, setModalBlock] = useState({});
  const [active, setActive] = useState(null);

  // Handler for editing or adding.
  const handleBlockAddOrEdit = useCallback(
    async (block) => {
      // Blocks without config.
      if (ConfiglessBlocks.includes(block.type)) {
        block = addIdToBlock(block);
        return addItem(block);
      }

      // lets add an item here and not on submit so the block has an id when the data gets updated from the api on render of the model

      if (!block.created) {
        block = addIdToBlock(block);
      }

      // Blocks to be configured.
      setModalBlock(block);
      modalControls.open();
    },
    [addItem, modalControls, setModalBlock, addIdToBlock]
  );
  // Fetches data for blocks - called on config modal changes.
  const getData = async (blocktype, config) => {
    const data = await fetchBlockData(blocktype, config);
    return data;
  };
  // Handler for modal submitting.
  const handleModalSubmit = (block) => {
    // Save or update the block.
    if (!block.created) {
      addItem(block);
    } else {
      updateBlock(block);
    }

    // Reset modal.
    setModalBlock({});
  };

  const handleModalCloseAfter = () => {
    setModalBlock({});
  }

  function handleDragEnd(event) {
    const { active, over } = event;
    setActive(null);
    if (active.id !== over.id) {
      setItems((state) => {
        const index = state.findIndex((i) => over.id === i.id);
        const activeIndex = state.findIndex((i) => active.id === i.id);

        return arrayMove(state, activeIndex, index);
      });
    }
  }
  function handleDragStart(event) {
    const active = items.find((i) => i.id === event.active.id);
    setActive(active);
  }

  return (
    <div className={`grid-wrapper ${singleColumn ? "singleColumn" : ""}`}>
      <DndContext
        onDragEnd={handleDragEnd}
        onDragStart={handleDragStart}
        modifiers={[restrictToVerticalAxis]}
        collisionDetection={closestCenter}
      >
        <div className="grid dnd">
          <SortableContext items={items} strategy={verticalListSortingStrategy}>
            {items.map((i) => {
              return (
                <Item
                  key={i.id}
                  id={i.id}
                  type={i.type}
                  item={i}
                  handleEditItem={handleBlockAddOrEdit}
                  updateBlock={updateBlock}
                  removeItem={removeItem}
                  dragging={active?.id === i.id}
                  defaultViewing={ConfiglessBlocks.includes(i.type)}
                />
              );
            })}
          </SortableContext>
          <DragOverlay>
            {active && (
              <Item
                id={active.id}
                item={active}
                type={active.type}
                defaultViewing={false}
              />
            )}
          </DragOverlay>
          <ReportBlockAdd config={{ addItem: handleBlockAddOrEdit }} />

          {modalBlock.type && (
            <ReportEditModal
              controls={modalControls}
              block={modalBlock}
              updateModalBlock={setModalBlock}
              getData={getData}
              getModalFormData={getModalFormData}
              updateBlock={updateBlock}
              onSubmit={handleModalSubmit}
              onAfterClose={handleModalCloseAfter}
              formatToDate={formatToDate}
              items={items}
              context={context}
            ></ReportEditModal>
          )}
        </div>
      </DndContext>
      {/* For debugging, shows json of current objects. */}
      {/* {<pre>{JSON.stringify(items, null, 4)}</pre>} */}
    </div>
  );
};
