import * as d3 from "d3";

import { useD3 } from "../../../utils/useD3";

import "./ReportBlock.scss";
import { useMemo } from "react";

function ReportBlockBarChart({
  config = { title: "" },
  content = { data: [] },
  maxWidth = 400
  // config = {},
}) {
  // Default to empty fixes a race condition with useD3 hook.
  const data = content?.data || [];

  const total = useMemo(() => {
    return content?.data?.reduce((p, { count }) => {
      p += count;
      return p;
    }, 0);
  }, [content]);

  const ref = useD3((svg) => {
    const height = 300;
    const width = 450;
    // Bigger margin for higher numbers
    const sideMargin = d3.max(data, (d) => d.count) > 999 ? 50 : 40;
    const margin = {
      top: 20,
      right: sideMargin,
      bottom: 0,
      left: sideMargin,
    };
    svg.attr("viewBox", "0 0 " + width + " " + (height + margin.top * 2));

    const xScale = d3
      .scaleBand()
      .domain(data.map((d) => d.label))
      .rangeRound([margin.left, width])
      .padding(0.1);

    const yScale = d3
      .scaleLinear()
      .domain([0, d3.max(data, (d) => d.count)])
      .rangeRound([height - margin.bottom, margin.top]);

    // Y Axis
    const yAxis = (g) =>
      g
        .attr("transform", `translate(${margin.left},0)`)
        .style("color", "steelblue")
        .call(d3.axisLeft(yScale).ticks(null, "s"))
        .call((g) => g.select(".domain").remove())
        .call((g) =>
          g
            .append("text")
            .attr("x", -margin.left)
            .attr("y", 10)
            .attr("fill", "currentColor")
            .attr("text-anchor", "start")
            .text(data.y1)
        )
        .call((g) =>
          g
            .selectAll("line")
            .attr("x1", width - margin.right)
            .attr("x2", 0)
            .attr("stroke", "#9BAFC3")
            .attr("stroke-width", "2")
        )
        .call((g) =>
          g
            .selectAll("text")
            .attr("dy", "0.4rem")
            .attr("font-size", "1.2rem")
            .attr("fill", "#003366")
        );

    svg.select(".y-axis").call(yAxis);
    svg.select(".y-axis .tick:first-child").remove();
    // X Axis
    const xAxis = (g) =>
      g.attr("transform", `translate(0,${height - margin.bottom})`).call(
        d3
          .axisBottom()
          .scale(xScale)
          .tickValues(
            d3
              .ticks(...d3.extent(xScale.domain()), width / 40)
              .filter((v) => xScale(v) !== undefined)
          )
          .tickSizeOuter(3)
      );

    svg.select(".x-axis").call(xAxis).selectAll("path").attr("fill", "#003366");
    // .attr('stroke-width', '1px');

    svg
      .select(".plot-area")
      .attr("fill", "lightblue")
      .selectAll(".bar")
      .data(data)
      .join("rect")
      .attr("class", "bar")
      .attr("x", (d) => xScale(d.label))
      .attr("width", xScale.bandwidth())
      .attr("y", (d) => yScale(d.count))
      .attr("height", (d) => yScale(0) - yScale(d.count))
      .attr("fill", (d) => d.color || "lightblue");
  }, data);

  if (!data) {
    return <p>No Data</p>;
  }

  return (
    <div className="report-block chart"  style={{ maxWidth }}>
      {!!config.title && <h2>{config.title}</h2>}
      {data.length === 0 ? (
        <p>There is not enough data</p>
      ) : (
        <svg ref={ref}>
          <g className="y-axis" />
          <g className="plot-area" />
          <g className="x-axis" />
        </svg>
      )}
      {config.legend ? (
        <div className="legend">
          <ul>
            {data.map(({ color, label, count }, _index) => {
              return (
                <li key={_index}>
                  <span
                    className="square"
                    style={{ backgroundColor: color }}
                  ></span>
                  {Math.floor((100 / total) * count)}% ({count}) {label}
                </li>
              );
            })}
          </ul>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export { ReportBlockBarChart };
