import React from "react";
import "./TopNav.scss";
import { Link, useLocation } from "react-router-dom";
import Loader from "./Loader";
import TopBar from "./TopBar.jsx";

const defaultMenuItems = [
  { text: "Audit on PEP", path: "/audits/" },
  { text: "Reports", path: "/reports/" },
  { text: "Coordinator", path: "/coordinator/" },
];

export function TopNav({
  title,
  path,
  user,
  menuItems = defaultMenuItems,
  logout,
}) {
  let role = "audit";
  if (!user) return <Loader />;
  if (user.role) role = user.role;

  const roleMap = {
    audit: [menuItems[0]],
    report: [menuItems[1]],
    manage: [menuItems[0], menuItems[1]],
    admin: [menuItems[0], menuItems[1], menuItems[2]],
    super: [menuItems[0], menuItems[1], menuItems[2]],
  };

  return (
    <header>
      <TopBar title={title} logout={logout} />
      {roleMap[role].length > 1 &&
        <nav id="topNav">
        <ul>
          {(roleMap[role] || []).map((item) => (
            <li key={item.path} className={"route" + (path.includes(item.path) ? " active" : "")}>
              <Link
                key={item.path}
                to={item.path}
              >
                {item.text}
              </Link>
            </li>
          ))}
        </ul>
        </nav>}
    </header>
  );
}

export default function WrappedTopNav({ ...params }) {
  const { pathname } = useLocation();
  return <TopNav path={pathname} {...params} />;
}
