import { ErrorBoundary } from "react-error-boundary";
import ModuleContext from "./context/ModuleContext.js";
import { Anchor } from "./components/stories/Button.jsx";

function ErrorFallback({ error, resetErrorBoundary }) {
  /* Module data. */
  const { name } = ModuleContext.useModule();
  const ts = +new Date();

  console.error(error);

  /* Bundle up error information. */
  const errorBundle = JSON.stringify(
    {
      ts,
      href: window.location.href,
      tool: name,
      message: error.message,
      stack: error.stack,
    },
    null,
    4
  );

  return (
    <div className="error-boundary" role="alert">
      <p>Oops, we're very sorry, but an error occured on this page!</p>
      <p>
        Please report this error to our support team by clicking this button:
        <Anchor
          href={`mailto:support@invision360.com?subject=Bug%20Report&body=${errorBundle}`}
        >
          Report Error
        </Anchor>
      </p>
      <pre>{errorBundle}</pre>
    </div>
  );
}

export default function InvisionErrorBoundary({ children }) {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>{children}</ErrorBoundary>
  );
}
