module.exports = {
  APIURL: process.env.REACT_APP_API_URL,
  ModuleId: process.env.REACT_APP_MODULE_ID,
  /* the module name in the config must match the module name found in the modules table in the database 
  the feature name is the key and the modules you want to enable it on are the values in the array
  */
  flaggedFeatures: {
    outcomesMonitoring: ["AR"],
  },
};
