import React from "react";
import "./button.scss";
import Loader from "./Loader";

/**
 * Primary UI component for user interaction
 *
 * @param {Object} attributes
 * @param {string} attributes.type 'primary'|'secondary'|'red'|'green'
 * @param {string} attributes.size 'large'|'medium'|'small'
 * @param {string} attributes.badge - Shows a badge to the top right of the button.
 */
export default function Button({
  children,
  type = "primary",
  label,
  size = "medium",
  loading = false,
  disabled = false,
  badge = "",
  ...props
}) {
  return (
    <Loader loading={loading}>
      <span className="button-wrapper">
        <button
          type="button"
          disabled={disabled}
          className={`invision-button ${type ? "btn-type-" + type : ""} ${
            size ? "btn-size-" + size : ""
          }`}
          {...props}
        >
          {label || children}
        </button>
        {badge ? <span className={`badge ${type}`}>{badge}</span> : ""}
      </span>
    </Loader>
  );
}

/**
 * A variation of the button component, but using an anchor for links.
 *
 * @param {*} attributes
 * @param {string} attributes.type 'primary'|'secondary'|'red'|'green'
 * @param {string} attributes.size 'large'|'medium'|'small'
 */
function Anchor({
  children,
  type = "primary",
  label,
  size = "medium",
  loading = false,
  ...props
}) {
  return (
    <Loader loading={loading}>
      <span className="button-wrapper">
        <a
          type="button"
          className={`invision-button ${type ? "btn-type-" + type : ""} ${
            size ? "btn-size-" + size : ""
          }`}
          {...props}
        >
          {label || children}
        </a>
      </span>
    </Loader>
  );
}

export { Button, Anchor };
