import { useEffect } from "react";
import { useMemo } from "react";
import { useState } from "react";

import Button from "./Button.jsx";

import "./FeedbackFormV2.scss";

const byIndex = ({ index: a_index }, { index: z_index }) => a_index - z_index;

const AdditionalComment = ({
  value,
  setValue = () => {},
  optional = false,
}) => {
  // Open latch.
  const [isOpen, setOpen] = useState(!!value);
  if (!isOpen)
    return (
      <button
        className="additional-comment-button"
        onClick={() => {
          setOpen(true);
        }}
      >
        <span>+</span> Add an answer
      </button>
    );

  return (
    <textarea
      className="additional-comment-text"
      required={false}
      value={value}
      onChange={(evt) => {
        evt.preventDefault();
        setValue(evt.target.value);
      }}
      placeholder="Additional comments"
    ></textarea>
  );
};

/**
 * Generates a feedback form from a JSON structure.
 *
 * @param {Array} form - An array of questions and optional answers.
 *
 * @returns
 */
export default function FeedbackForm({
  // Handles value updates on input.
  onChange = () => {},

  // Signals the submission of the form.
  onSubmit,

  // The form structure/questions.
  form = {},
}) {
  // States.
  const [values, setValues] = useState({});
  const [pagination, setPagination] = useState({});

  // Bubble events up to parent.
  useEffect(() => {
    onChange &&
      onChange({
        ...values,
        // valid: !form?.filter(
        //   (q) => q.required === false || (q.required && !values[q.id])
        // ).length,
      });
  }, [values, onChange]);

  // Setup pagination.
  useEffect(() => {
    // Calc number of pages.
    const maxPage =
      form?.SurveyQuestions?.reduce((prev, { pageIndex }) => {
        return pageIndex > prev ? pageIndex : prev;
      }, 0) || 0;

    // Update state.
    setPagination({
      current: 0,
      pages: maxPage,
    });
  }, [form]);

  /**
   * Returns questions based on pagination, or lack of.
   */
  const formQuestions = useMemo(() => {
    // Filter by page number and sort by index.
    return form?.SurveyQuestions?.filter(
      ({ pageIndex }) =>
        (!pagination.current && pagination.current !== 0) ||
        pagination.current === pageIndex
    ).sort(byIndex);
  }, [form, pagination]);

  /**
   * Returns the offset of the current page's question indexes, letting us auto-generate question numbers.
   */
  const pageIndexOffset = useMemo(() => {
    // First page is zero.
    if (pagination.current === 0) return 0;

    // Any other pages count previous questions.
    return form.SurveyQuestions.filter(
      ({ pageIndex: questionPageIndex }) =>
        questionPageIndex < pagination.current
    ).length;
  }, [form, pagination]);

  /**
   * Validates the question on the current page.
   */
  const isPageValid = useMemo(() => {
    // Questions must be optional or have an answer.
    return [...formQuestions].every(
      ({ id, optional }) => optional || values[id]?.answerId || values[id]?.text
    );
  }, [formQuestions, values]);

  /**
   * Validates the entire form.
   */
  const isFormValid = useMemo(() => {
    // Questions must be optional or have an answer.
    return [...form?.SurveyQuestions].every(
      ({ id, optional }) => optional || values[id]?.answerId || values[id]?.text
    );
  }, [values, form]);

  /**
   * Generates the pagination bar based on current paging.
   */
  const paginationBar = useMemo(() => {
    return (
      <>
        {pagination.current > 0 && (
          <Button
            onClick={(e) => {
              e.preventDefault();
              setPagination({
                ...pagination,
                current: pagination.current - 1,
              });
            }}
          >
            Back
          </Button>
        )}
        {pagination.current < pagination.pages && (
          <Button
            onClick={(e) => {
              e.preventDefault();
              setPagination({
                ...pagination,
                current: pagination.current + 1,
              });
            }}
            disabled={!isPageValid}
          >
            Next
          </Button>
        )}
        {typeof onSubmit === "function" &&
          pagination.current === pagination.pages && (
            <Button
              onClick={(e) => {
                e.preventDefault();
                onSubmit(values);
              }}
              disabled={!isFormValid}
            >
              Submit
            </Button>
          )}
      </>
    );
  }, [pagination, values, isPageValid, setPagination, isFormValid, onSubmit]);

  return (
    <form className="feedback">
      {formQuestions.map(
        (
          {
            id: questionId,
            index,
            name,
            optional,
            pageIndex,
            surveyId,
            type,
            SurveyQuestionAnswers,
          },
          q_index
        ) => {
          return (
            <div className="question" key={q_index}>
              <h3>
                Q{q_index + 1 + pageIndexOffset} {name}
                {optional ? (
                  <span className="optional" title="This question is optional.">
                    (optional)
                  </span>
                ) : (
                  <span
                    className="required"
                    title="This question is mandatory."
                  >
                    (required)
                  </span>
                )}
              </h3>
              <div className="answers">
                {type === "rating_scale_with_comments" && (
                  <>
                    {/* Rating Options */}
                    {SurveyQuestionAnswers.sort(byIndex).map(
                      (
                        { id, surveyanswerId: { id: answerId, label, emoji } },
                        rs_index
                      ) => {
                        return (
                          <button
                            key={rs_index}
                            className={
                              "answer " +
                              (answerId === values[questionId]?.answerId
                                ? "selected"
                                : "")
                            }
                            onClick={(evt) => {
                              evt.preventDefault();
                              setValues({
                                ...values,
                                [questionId]: {
                                  ...values[questionId],
                                  answerId,
                                },
                              });
                            }}
                          >
                            {emoji && (
                              <span className="emoji-icon">{emoji}</span>
                            )}
                            {label}
                            <span
                              className={`select-icon ${!emoji ? "left" : ""}`}
                            ></span>
                          </button>
                        );
                      }
                    )}
                    <AdditionalComment
                      value={values[questionId]?.text}
                      setValue={(value) => {
                        setValues({
                          ...values,
                          [questionId]: { ...values[questionId], text: value },
                        });
                      }}
                    />
                  </>
                )}
                {type === "rating_scale" &&
                  SurveyQuestionAnswers.sort(byIndex).map(
                    (
                      { id, surveyanswerId: { id: answerId, label, emoji } },
                      rs_index
                    ) => {
                      return (
                        <button
                          key={rs_index}
                          className={
                            "answer " +
                            (answerId === values[questionId]?.answerId
                              ? "selected"
                              : "")
                          }
                          onClick={(evt) => {
                            evt.preventDefault();
                            setValues({
                              ...values,
                              [questionId]: { answerId },
                            });
                          }}
                        >
                          {emoji && <span className="emoji-icon">{emoji}</span>}
                          {label}
                          <span
                            className={`select-icon ${!emoji ? "left" : ""}`}
                          ></span>
                        </button>
                      );
                    }
                  )}
                {type === "text" && (
                  <AdditionalComment
                    optional={optional}
                    value={values[questionId]?.text}
                    setValue={(value) => {
                      setValues({
                        ...values,
                        [questionId]: { ...values[questionId], text: value },
                      });
                    }}
                  />
                )}
              </div>
            </div>
          );
        }
      )}
      <div className="pagination">{paginationBar}</div>
    </form>
  );
}
