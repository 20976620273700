import { format } from "date-fns";
import { ReportBlockBarChart } from "./GridBlocks/ReportBlockBarChart";

const convertToJSON = (config, content) => {
    const title = {
    type: "heading",
    attrs: {
        level: 2
    },
    content: [
        {
            type: "text",
            text: config.title
        }
    ]
    }
    const subTitle = {
    type: "paragraph",
    content: [
        {
            type: "text",
            text: `Comments for audits completed between the ${format(new Date(config.from), "do 'of' MMMM yyyy")} and the ${format(new Date(config.to), "do 'of' MMMM yyyy")}`
        }
    ]
    }
    const getComments = (comments, type) => {
        const commentJSON = comments?.length ?
        {
            type: "bulletList",
            content: comments?.map((c) => ({
            type: "listItem",
            content: [
                {
                    type: "paragraph",
                    content: [
                        {
                            type: "text",
                            text: c
                        }
                    ]
                }
            ]
            }))
        }
        : {
            type: "paragraph",
            content: [
                {
                type: "text",
                text: `There are no ${type} comments`
                }
            ]
        }
        return ([
        {
            type: "heading",
            attrs: {
                "level": 3
            },
            content: [
                {
                    type: "text",
                    text: "Theme Comments"
                }
            ]
        },
        commentJSON
        ])
    }
    return [
    title,
    subTitle,
    ...getComments(content.themeComments, 'theme'),
    // ...getComments(content.explanatoryComments)
    ]
}
export const textConverter = (doc) => {
    const heading = (text, { level }) => {
        const types = [
            <h1>{marks(text)}</h1>,
            <h2>{marks(text)}</h2>,
            <h3>{marks(text)}</h3>,
            <h4>{marks(text)}</h4>
        ]
        return types[level - 1]
    }
    const marks = (content) => {
        return content?.map(({text, marks}) => marks ? <b>{text}</b> : text)
    }
    const paragraph = (content) => {
        return <p>{marks(content)}</p>
    }
    const bulletList = (content) => {
        return (<ul>
            {content.map((doc) => <li>{ textConverter(doc) }</li>)}
        </ul>)
    }
    const horizontalRule = () => {
        return <hr></hr>
    }
    const graph = (content, attrs) => {
        if(attrs.legend)
            return <ReportBlockBarChart
                content={content}
                config={{ legend: attrs.legend }}
            />
        return <ReportBlockBarChart content={content}/>
    }
    const types = {
        paragraph,
        heading,
        bulletList,
        horizontalRule,
        graph
    }
    return (<>{doc.content.map(({ type, content, attrs }) => types[type](content, attrs))}</>)
}
export const getPreviewContent = (items) => {
    return items.filter(i => i.content)
            .filter(i => i.visible)
            .map(({id, content, config, type }) =>
                parseContent[type](id, config, content))
}
export const parseContent = {
    CUSTOM_TEXT: (id, { content }) => ({ id, content }),
    COMMENTS: (id, c, b) => ({
        id,
        content: convertToJSON(c, b)
    }),
    CUSTOM_CHART: (id, { title, legend }, content) => ({
        id,
        content: [{
            type: "heading",
            attrs: {
                level: 2
            },
            content: [
                {
                    type: "text",
                    text: title
                }
            ]
        },
        {
            type: "graph",
            content,
            attrs: {
                legend
            }
        },
    ]
    })
}
