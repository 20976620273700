import useCopyToClipboard from "@utilityjs/use-copy-to-clipboard";
import React, { useEffect } from "react";
import "./FeedbackModal.scss";

// SVGs.
import { ReactComponent as CopySVG } from "../stories/assets/copy.svg";
import { ReactComponent as CopiedSVG } from "../stories/assets/copied.svg";
import { Modal } from "./Modal.jsx";

function CopyButton({ text }) {
  const [isCopied, setIsCopied] = React.useState(false);

  /* Reset after a while. */
  useEffect(() => {
    setTimeout(() => {
      if (!!isCopied) setIsCopied(false);
    }, 1500);
  }, [isCopied]);

  const copy = useCopyToClipboard();

  return (
    <button
      onClick={async () => void setIsCopied(await copy(text))}
      className={`${(isCopied && "copied") || ""}`}
    >
      {isCopied ? "Copied!" : "Copy Link"}
      {isCopied ? <CopiedSVG /> : <CopySVG />}
    </button>
  );
}

export default function FeedbackModal({
  title = "Feedback Surveys",
  controls,
  planId,
  baseUrl = window.location.origin,
  links = [],
}) {
  
  return (
    <Modal title={title} controls={controls} size="mini">
      <div className="feedback-modal">
        {links
          .sort((a, z) => a.index - z.index)
          .map(({ name, type }) => {
            return (
              <div className="link">
                <div>
                  <span>{name}</span>
                  <CopyButton
                    text={baseUrl + "/feedback/" + type + "/" + planId}
                  />
                </div>
              </div>
            );
          })}
      </div>
    </Modal>
  );
}
