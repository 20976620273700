import "./Tooltip.scss";

function ToolTip({ text, title }) {
  return (
    <div className="tooltip">
      <p>&#9432; {title}</p>
      <div className="tooltip-text">
        <p>{text}</p>
      </div>
    </div>
  );
}

export default ToolTip;